import { GET_COMPANY, GET_COUNTRY, IS_COLLED } from "../types"

const initialState = {
  colled: false,
  country: [],
}

export const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_COLLED:
      return {
        ...state,
        colled: action.payload,
      }

    case GET_COUNTRY:
      return {
        ...state,
        country: action.payload,
      }

    default:
      return state
  }
}
