import styled from "styled-components";
import React, { useState} from "react"
import {Edit} from "../../assets/svg-component/Edit"
import {Save} from "../../assets/svg-component/Save"
import {Close} from "../../assets/svg-component/Close"
import { useDispatch} from "react-redux";
import {supportDetailsUpdate} from "../../store/actions/SupportAction";
const InputStyle = styled.div`
    position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 8px;
  height: auto;
  width: 100%;
  margin: 0;
  padding: 4px 4px 4px 8px;
  border: 1px solid #d9e1e7;
`
const Input = styled.textarea`
    margin: 0;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 8px;
  &::-webkit-scrollbar {
    display: none;
  }
`
const Text = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
color: #6C727A;
`
const WrapperBtn = styled.div`
    display: flex;
  flex-direction: column;
  color: #5dba2f;
  cursor: pointer;
`
const EditInput = ({variant="",record}) => {
    const dispatch = useDispatch();
    const [edit, setEdit] = useState(false)
    const [val, setVal] = useState(variant === "support_comment" ? record?.support_comment :variant === "receiver_zip" ? record?.receiver_zip : "")
    const handleSave = () => {
        dispatch(supportDetailsUpdate(record.ID, {[variant]:val})).then(res =>{
            if(res){
                setEdit(false)
            }
        })
    }
    return(<InputStyle>{!edit ?<Text>{val}</Text> :<Input value={val} onChange={(e)=>setVal(e.target.value)}/>}{edit ?<WrapperBtn><Save onClick={handleSave}/> <Close onClick={()=>setEdit(false)}/> </WrapperBtn>: <Edit onClick={() => setEdit(!edit)}/>}</InputStyle>)
}
export default EditInput