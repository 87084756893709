import React from "react"
import "antd/dist/antd.css"
import { Table } from "antd"

const columns = [
  {
    title: "Recipient",
    dataIndex: "recipient",
  },
  {
    title: "Tracking",
    dataIndex: "tracking",
  },
  {
    title: `Weight:balance`,
    dataIndex: "weight",
  },
  { title: `Count:count`, dataIndex: "count" },
  { title: "HayPost", dataIndex: "hayPost" },
]

const data = [
  {
    key: "1",
    recipient: "John Brown",
    tracking: 32,
    weight: "New York No. 1 Lake Park",
    count: 5555,
    hayPost: "hdgf",
  },
  {
    key: "2",
    recipient: "John Brown",
    tracking: 32,
    weight: "New York No. 1 Lake Park",
    count: 5555,
    hayPost: "hdgf",
  },
  {
    key: "3",
    recipient: "John Brown",
    tracking: 32,
    weight: "New York No. 1 Lake Park",
    count: 5555,
    hayPost: "hdgf",
  },
  {
    key: "4",
    recipient: "John Brown",
    tracking: 32,
    weight: "New York No. 1 Lake Park",
    count: 5555,
    hayPost: "hdgf",
  },
  {
    key: "5",
    recipient: "John Brown",
    tracking: 32,
    weight: "New York No. 1 Lake Park",
    count: 5555,
    hayPost: "hdgf",
  },
  {
    key: "6",
    recipient: "John Brown",
    tracking: 32,
    weight: "New York No. 1 Lake Park",
    count: 5555,
    hayPost: "hdgf",
  },
  {
    key: "7",
    recipient: "John Brown",
    tracking: 32,
    weight: "New York No. 1 Lake Park",
    count: 5555,
    hayPost: "hdgf",
  },
  {
    key: "8",
    recipient: "John Brown",
    tracking: 32,
    weight: "New York No. 1 Lake Park",
    count: 5555,
    hayPost: "hdgf",
  },
]
let [a, b, c, ...rest] = data

const TableHaypost = ({ active, setActive }) => (
  <div>
    <Table
      columns={columns}
      dataSource={!active ? [a, b, c] : data}
      size="middle"
      pagination={false}
    />
  </div>
)

export default TableHaypost
