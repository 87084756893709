import React from "react"

import { Route, Routes } from "react-router-dom"
import {
  Login,
  Orders,
  Parcel,
  Tracking,
  Manifest,
  Delivery,
  Branches,
  Company,
  Support,
  HayPost,
} from "../containers"
import Scan from "../containers/Scan";
import Cargo from "../containers/Cargo";
import Lockers from "../containers/Lockers";

const PageRouter = () => (
  <Routes>
    <Route path="/" element={<Orders />} />
    <Route path="/parcel" element={<Parcel />} />
    <Route path="/login" element={<Login />} />
    <Route path="/tracking" element={<Tracking />} />
    <Route path="/manifest" element={<Manifest />} />
    <Route path="/delivery" element={<Delivery />} />
      <Route path="/scan-packages"  element={<Scan />}/>
      <Route path="/cargo"  element={<Cargo />}/>
      <Route  path="/lockers" element={<Lockers />} />
    {/*<Route path="/branches" element={<Branches />} />*/}
    {/*<Route path="/country" element={<Branches />} />*/}
    <Route path="/support" element={<Support />} />
    {/*<Route path="/haypost" element={<HayPost />} />*/}
  </Routes>
)

export default PageRouter
