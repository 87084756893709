import React from "react";
import "antd/dist/antd.css";
import { Select } from "antd";

const { Option } = Select;

const SelectOptionGroup = ({ value }) => {
  const handleChange = (value) => {};

  return (
    <Select
      showSearch
      onChange={handleChange}
      defaultValue="All Countries"
      style={{
        width: 120,
      }}
      allowClear
    >
      <Option value="all">All Countries</Option>
    </Select>
  );
};

export default SelectOptionGroup;
