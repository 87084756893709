import { Form, Input } from "antd";
import React from "react";
import "./ant-table.scss";

export const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td
      {...restProps}
      style={{
        background:
          dataIndex === "receiver_phone"
            ? "rgba(93, 186, 47, 0.04)"
            : dataIndex === "receiver_address_line1"
            ? "rgba(93, 186, 47, 0.04)"
            :dataIndex === "id_number"
            ? "rgba(93, 186, 47, 0.04)"
            : "transparent",
      }}
    >
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          <Input className={"inp"} />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
