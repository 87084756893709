import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import styles from "./slider.module.scss";

import dot from "../../assets/images/dot.svg";
import mail from "../../assets/images/mail.svg";
import mail_active from "../../assets/images/mail_active.svg";
import logo from "../../assets/images/logo.svg";
import box_2 from "../../assets/images/box_2.svg";
import doc from "../../assets/images/document.svg";
import ImageGroup from "../../components/ImageGroup";
import scan from "../../assets/images/scan.svg";
import support from "../../assets/images/support.svg";
import company from "../../assets/images/company.svg";
import delivery from "../../assets/images/delivery.svg";
import onex_logo from "../../assets/images/onex_logo.svg";
import boxActive from "../../assets/images/box_active.svg";
import arrow_down from "../../assets/images/arrow_down.svg";
import branch from "../../assets/images/branch_passive.svg";
import open_slider from "../../assets/images/open_slider.svg";
import docActive from "../../assets/images/document_active.svg";
import branchActive from "../../assets/images/branch_active.svg";
import companyActive from "../../assets/images/company_active.svg";
import deliveryActive from "../../assets/images/delivery_active.svg";
import support_active from "../../assets/images/support_active.svg";
import cargo from "../../assets/images/cargo.svg";
import { sliderItems } from "../../utils";
import { setBool } from "../../store/actions/OrdersAction";
import locker_active from "../../assets/images/locker.svg";

const Sidebar = () => {
  const [rotate, setRotate] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [active, setActive] = useState({
    orders: true,
  });

  const dispatch = useDispatch();
  const location = useLocation().pathname;

  const handleRotate = () => {
    setRotate(!rotate);
  };

  const handleActive = (e) => {
    const { id } = e.target;
    setActive({ [id]: !active[id] });
  };

  const handleClickLogo = () => {
    setClicked(!clicked);
    dispatch(setBool(!clicked));
  };

  const navItems = sliderItems.map(({ id, to, name }) => {
    return name !== "parcel" ? (
      <Link
        key={id}
        to={to}
        id={id}
        onClick={handleActive}
        className={
          location == to && !clicked
            ? styles.main_slider__icons_item__active
            : !name in active
            ? styles.main_slider__icons_item
            : location == to && clicked
            ? styles.main_sliderOpen__iconsOpen_itemOpen__activeOpen
            : styles.main_sliderOpen__iconsOpen_itemOpen
        }
      >
        {name == "orders" ? (
          <ImageGroup
            id={id}
            name={name}
            src={name in active ? docActive : doc}
            className={styles.main_slider__icons_item_image}
          />
        ) : name == "parcel" ? (
          <ImageGroup
            id={id}
            name={name}
            src={name in active ? docActive : doc}
            className={styles.main_slider__icons_item_image}
          />
        ) : name == "delivery" ? (
          <ImageGroup
            id={id}
            name={name}
            src={name in active ? deliveryActive : delivery}
            className={styles.main_slider__icons_item_image}
          />
        ) : name == "branches" ? (
          <ImageGroup
            id={id}
            name={name}
            src={name in active ? branchActive : branch}
            className={styles.main_slider__icons_item_image}
          />
        ) : name == "country" ? (
          <ImageGroup
            id={id}
            name={name}
            src={"company" in active ? company : companyActive}
            className={styles.main_slider__icons_item_image}
          />
        ) : name === "haypost" ? (
          <ImageGroup
            id={id}
            name={name}
            src={"haypost" in active ? mail_active : mail}
            className={styles.main_slider__icons_item_image}
          />
        ) : name === "scan" ? (
          <ImageGroup
            id={id}
            name={name}
            src={scan}
            className={styles.main_slider__icons_item_image}
          />
          ): name === "lockers" ? (
            <ImageGroup
              id={id}
              name={name}
              src={locker_active}
              className={styles.main_slider__icons_item_image}
            />
            ) : name === "cargo" ? (
            <ImageGroup
              id={id}
              name={name}
              src={cargo}
              className={styles.main_slider__icons_item_image}
            />
        ) : (
          <ImageGroup
            id={id}
            name={name}
            src={"support" in active ? support_active : support}
            className={styles.main_slider__icons_item_image}
          />
        )}

        <p
          id={id}
          className={
            clicked
              ? styles.main_slider__icons_item_text_clicked
              : styles.main_slider__icons_item_text
          }
        >
          {name}
        </p>
      </Link>
    ) : (
      <div key={id}>
        <Link
          to={to}
          onClick={handleActive}
          id="parcel"
          className={
            location == "/parcel" && !clicked
              ? styles.main_slider__icons_item__active
              : !"parcel " in active
              ? styles.main_slider__icons_item
              : location == "/parcel" && clicked
              ? styles.main_sliderOpen__iconsOpen_itemOpen__activeOpen
              : styles.main_sliderOpen__iconsOpen_itemOpen
          }
        >
          <ImageGroup
            id="parcel"
            name={"parcel"}
            src={"parcel" in active ? boxActive : box_2}
            className={styles.main_slider__icons_item_image}
          />

          <div
            className={
              clicked
                ? styles.main_sliderOpen__iconsOpen_itemOpen__select_checked
                : styles.main_sliderOpen__iconsOpen_itemOpen__select
            }
          >
            <p
              id="parcel"
              className={styles.main_slider__icons_item_text_parcel}
            >
              Parcel
            </p>
            <ImageGroup
              onClick={handleRotate}
              id="rotate"
              src={arrow_down}
              className={
                rotate
                  ? styles.main_sliderOpen__iconsOpen_itemOpen__select_image_rotate
                  : styles.main_sliderOpen__iconsOpen_itemOpen__select_image
              }
            />
          </div>
        </Link>

        {clicked && (
          <Link
            onClick={handleActive}
            id="manifest"
            to="/manifest"
            className={
              rotate && location == "/manifest"
                ? styles.main_sliderOpen__iconsOpen_itemOpen__option_active
                : rotate
                ? styles.main_sliderOpen__iconsOpen_itemOpen__option
                : styles.main_sliderOpen__iconsOpen_itemOpen__option_none
            }
          >
            <ImageGroup
              id="manifest"
              src={dot}
              className={styles.main_sliderOpen__iconsOpen_itemOpen__option_dot}
            />
            <p
              id="parcel"
              className={
                clicked
                  ? styles.main_slider__icons_item_text_clicked
                  : styles.main_slider__icons_item_text
              }
            >
              Manifest
            </p>
          </Link>
        )}
      </div>
    );
  });
  return (
    <section className={clicked ? styles.main_sliderOpen : styles.main_slider}>
      <ImageGroup
        src={open_slider}
        className={
          clicked
            ? styles.main_sliderOpen__openImageOpen
            : styles.main_slider__openImage
        }
        onClick={handleClickLogo}
      />
      <Link
        to="/"
        className={
          clicked ? styles.main_slider__logo_open : styles.main_slider__logo
        }
      >
        {clicked ? <ImageGroup src={onex_logo} /> : <ImageGroup src={logo} />}
      </Link>
      <div className={styles.main_slider__icons}>{navItems}</div>
    </section>
  );
};

export default Sidebar;
{
  /* NEED TO ADD*/
}
{
  /* <Link
          to="/tracking"
          onClick={handleActive}
          id="tracking"
          className={
            location == "/tracking" && !clicked
              ? styles.main_slider__icons_item__active
              : !"tracking " in active
              ? styles.main_slider__icons_item
              : location == "/tracking" && clicked
              ? styles.main_sliderOpen__iconsOpen_itemOpen__activeOpen
              : styles.main_sliderOpen__iconsOpen_itemOpen
          }
        >
          <ImageGroup
            id="tracking"
            name={"tracking"}
            src={box_3}
            className={styles.main_slider__icons_item_image}
          />

          {clicked && (
            <p id="tracking" className={styles.main_slider__icons_item_text}>
              Tracking
            </p>
          )}
        </Link> */
}
