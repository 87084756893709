import { AxiosInstance } from "../../utils/AxiosInstance";
import { GET_EVENTS } from "../types";

export const getEvent = () => async (dispatch) => {
  const { data } = await AxiosInstance.get(`admin/events`);

  dispatch({
    type: GET_EVENTS,
    payload: data.data,
  });
};
