import styles from "../Delivery/delivery.module.scss";
import React, { useEffect, useRef, useState } from "react";
import { debounceHook } from "../../hooks/useDebounce";
import axios from "axios";
import { AxiosInstance } from "../../utils/AxiosInstance";
import { Table } from "antd";
import { Eye } from "../../assets/svg-component/Eye";
import { useSelector } from "react-redux";

export default function Scan() {
  const debouncedFilter = debounceHook((fn) => fn(), 1500);
  const [data, setData] = useState();
  const inputRef = useRef();

  const [result, setResult] = useState([]);
  const isActive = useSelector((state) => state.ordersReducer.bool);

  const handleScan = (e) => {
    if (e.key === "Enter") {
      AxiosInstance.post("/admin/orders/track/scan", {
        tracking_number: e.target.value,
      })
        .then((res) => {
          setData(res.data.data);
        })
        .finally(() => {
          inputRef.current.value = "";
        });
    }
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Pickup Point",
      dataIndex: "pickup_point",
      key: "pickup_point",
      render: (_, record) => (
        <p>
          {record
            ? record.pickup_point
              ? record.pickup_point?.address +
                "" +
                record.pickup_point?.description
              : null
            : ""}
        </p>
      ),
    },
    {
      title: "Name of User",
      dataIndex: "receiver_full_name",
      key: "receiver_full_name",
    },
    {
      title: "Tracking code",
      dataIndex: "tracking_number",
      key: "tracking_number",
    },
    {
      title: "Weight",
      dataIndex: "parcel_weight",
      key: "parcel_weight",
    },
    {
      title: "Parcel",
      dataIndex: "parcel",
      key: "parcel",
      render: (_, record) => <p>{record ? record.parcel.parcel_name : null}</p>,
    },
    {
      title: "Country",
      dataIndex: "origin",
      key: "origin",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];
  let dataSource = [data];

  return (
    <div
      className={`container
       ${
         isActive
           ? `main_delivry_mobile_active ${styles.main_delivery__wrapper_active}`
           : `main_delivry_mobile ${styles.main_delivery__wrapper}`
       }    
     `}
    >
      <div style={{ margin: "0 auto", width: "100%" }}>
        <section className={styles.scan}>
          <input
            type="text"
            ref={inputRef}
            onKeyDown={handleScan}
            autoFocus
            placeholder={"Scan Package"}
          />
        </section>
        <div style={{ marginTop: 35 }}>
          <Table columns={columns} dataSource={dataSource} />
        </div>
      </div>
    </div>
  );
}
