import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./manifest.module.scss";

import { manifesttableHead } from "../../utils";

import { ParcelTable } from "../../components";
import {
  getManifest,
  getOrderByManifestId,
  setColled,
} from "../../store/actions";

const Manifest = () => {
  const called = useSelector((state) => state.CommonReducer.colled);
  const isActive = useSelector((state) => state.ordersReducer.bool);

  const dispatch = useDispatch();
  const isLoggedIn = localStorage.getItem("accessToken");
  const user = JSON.parse(localStorage.getItem("userInfo"));

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn == null) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate, user]);

  useEffect(() => {
    if (called == false) {
      dispatch(getManifest());
    }
  }, []);

  const ordersFromManifest = (id) => {
    dispatch(getOrderByManifestId(id));
    dispatch(setColled(true));
  };

  return (
    <section
      className={`container ${
        isActive
          ? ` main_parcel_mobile_active ${styles.main_manifest_active}`
          : `main_parcel_mobile ${styles.main_manifest}`
      } `}
    >
      <div className="">
        <ParcelTable
          getDataById={ordersFromManifest}
          tableHead={manifesttableHead}
          type="manifest"
        />
      </div>
    </section>
  );
};

export default Manifest;
