import {
  ORDERS_BY_MANIFEST_ID,
  SET_BOOL,
  SET_ORDERS,
  FILTERED_DATAS,
  SET_ORDERS_DETAILS,
  SET_ORDERS_ERROR,
  SEARCH_ORDERS,
  SET_NEW_EVENT,
  SET_NEW_EVENT_ERROR,
  DISTRIBUTE_ORDERS,
  DISTRIBUTE_ORDERS_ERROR,
  GET_COMPANY,
} from "../types"

const initialState = {
  orders: [],
  oneManofestOrders: [],
  company: [],
  oneOrder: {},
  allOrders: {},
  bool: false,
  successMessage: "",
  errorMessage: "",
  eventMessage: "",
  eventError: "",
  successDistribute: "",
  errorDistribute: "",
}

export const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDERS:
      return {
        ...state,
        orders: action.payload.data,
        allOrders: action.payload,
        successMessage: "Success!",
      }
    case SET_ORDERS_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      }

    case SET_BOOL:
      return {
        ...state,
        bool: action.payload,
      }

    case ORDERS_BY_MANIFEST_ID:
      return {
        ...state,
        oneManofestOrders: action.payload,
      }

    case SET_ORDERS_DETAILS:
      return {
        ...state,
        oneOrder: action.payload,
      }

    case SEARCH_ORDERS:
      return {
        ...state,
        orders: action.payload.data,
      }

    case FILTERED_DATAS:
      return {
        ...state,
        orders: action.payload.data,
        allOrders: action.payload,
      }
    case SET_NEW_EVENT:
      return {
        ...state,
        orders: action.payload.orders,
        allOrders: action.payload.data,
        eventMessage: action.payload.message,
      }

    case SET_NEW_EVENT_ERROR:
      return {
        ...state,
        eventError: action.payload,
      }

    case DISTRIBUTE_ORDERS:
      return {
        ...state,
        successDistribute: action.payload,
      }

    case DISTRIBUTE_ORDERS_ERROR:
      return {
        ...state,
        errorDistribute: action.payload,
      }

    case GET_COMPANY:
      return {
        ...state,
        company: action.payload,
      }
    default:
      return state
  }
}
