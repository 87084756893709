const date = new Date();
let year = date.getFullYear();

let month =
  `${date.getMonth()}`.length === 1 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;

let day =
  `${date.getDate()}`.length == 1
    ? `0${date.getDate() + 1}`
    : date.getDate() + 1;

let hour =
  `${date.getHours()}`.length == 1 ? `0${date.getHours()}` : date.getHours();

let minute =
  `${date.getMinutes()}`.length == 1
    ? `0${date.getMinutes()}`
    : date.getMinutes();

let second =
  `${date.getSeconds()}`.length == 1
    ? `0${date.getSeconds()}`
    : date.getSeconds();

export const today = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
