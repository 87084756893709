import { AxiosInstance } from "../../utils/AxiosInstance";
import { LOGIN_SUCCESS, LOGIN_ERROR } from "../types";

export const auth = (params, navigate) => async (dispatch) => {
  try {
    const { data } = await AxiosInstance.post(`/login`, params);
    localStorage.setItem("accessToken", data.data.token);
    localStorage.setItem("userInfo", JSON.stringify(data.data.user));

    dispatch({
      type: LOGIN_SUCCESS,
      payload: "Success!",
    });
    setTimeout(() => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: "",
      });
    }, 2000);
  } catch (error) {
    dispatch({
      type: LOGIN_ERROR,
      payload: "Email or password is not currect!",
    });
    setTimeout(() => {
      dispatch({
        type: LOGIN_ERROR,
        payload: "",
      });
    }, 2000);
  }
};
