import {
  ATTACHE_COURIER,
  EXPORT_ERROR,
  FILTER_DELIVERY,
  SEARCH_COURIER,
  SEARCH_DELIVERY,
  SET_DELIVERY,
  CHANGE_DELIVERY_STATUS,
  ATTACHE_ERROR,
} from "../types"

const initialState = {
  delivery: [],
  allDeliveryData: {},
  successMessage: "",
  errorMessage: "",
  saveMessage: "",
  saveError: "",
}

export const DeliveryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DELIVERY:
      return {
        ...state,
        delivery: action.payload.data,
        allDeliveryData: action.payload,
      }

    case SEARCH_COURIER:
      return {
        ...state,
        delivery: action.payload,
      }

    case SEARCH_DELIVERY:
      return {
        ...state,
        delivery: action.payload.data,
      }

    case EXPORT_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      }

    case FILTER_DELIVERY:
      return {
        ...state,
        delivery: action.payload.data,
      }

    case ATTACHE_COURIER:
      return {
        ...state,
        delivery: action.payload.data,
        saveMessage: action.payload.message,
      }

    case ATTACHE_ERROR:
      return {
        ...state,
        saveError: action.payload,
      }

    case CHANGE_DELIVERY_STATUS:
      return {
        ...state,
        delivery: action.payload.data,
        successMessage: action.payload.message,
      }
    default:
      return state
  }
}
