import styled from "styled-components"

const Card = styled.div`
  width: ${({ width }) => width};
  color: ${({ color }) => color};
  height: ${({ height }) => height};
  min-height: ${({ min_h }) => min_h};
  max-height: ${({ max_h }) => max_h};
  border: ${({ border }) => border};
  padding: ${({ padding }) => padding || "18px"};
  background-color: ${({ bgc }) => bgc || "#EBEBEB"};
  margin: ${({ margin }) => margin};
  border-radius: ${({ radius }) => radius || "12px 12px 0px 0px"};
  overflow-x: ${({ o_x }) => o_x};
  overflow-y: ${({ o_y }) => o_y};
`
export default Card
