import React, { useEffect, useState, useRef } from "react"
import { useFormInputValidation } from "react-form-input-validation"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import { Select, DatePicker, Modal, message } from "antd"

import moment from "moment"

import styles from "./modalGroup.module.scss"

import InputGroup from "../InputGroup"
import ButtonGroup from "../ButtonGroup"

import close from "../../assets/images/close_circle.svg"
import arrow_down from "../../assets/images/arrow_select.svg"

import { getCourier, removeCourier, getCompanies } from "../../store/actions"
import circle_close from "../../assets/images/close_circle.svg"
import { getEvent } from "../../store/actions/EventsAction"
import { translateNumber, today } from "../../utils"
import SelectOptionGroup from "../SelectOptionGroup"

const { Option, OptGroup } = Select

const dateFormat = `YYYY-MM-DD HH:mm:ss`

const ModalGroup = ({
  handleCnacel,
  sentEvent,
  createParcel,
  editedItem,
  type,
  setActive,
  addData,
  courierData,
  setCourierData,
  oneOrder,
  closeToggle,
}) => {
  const modalRef = useRef()

  const [confirmModal, setConfirmModal] = useState(false)
  const [companyId, setCompanyId] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState(
    `${oneOrder?.current_event?.event?.event_code} `
  )

  const company = useSelector((state) => state.ordersReducer.company)
  const manifestIds =
    editedItem &&
    "manifests" in editedItem &&
    editedItem?.manifests?.map((item) => {
      return {name: item.name, id: item.id}
    })

  const [datas, setDatas] = useState({
    company_id:
      editedItem && "company" in editedItem
        ? editedItem?.company?.id
        : companyId,
    parcel_name:
      editedItem && "parcel_name" in editedItem ? editedItem.parcel_name : "",
    air_way_bill:
      editedItem && "air_way_bill" in editedItem ? editedItem.air_way_bill : "",

    in_my_way_date:
      editedItem && "in_my_way_date" in editedItem
        ? editedItem.in_my_way_date
        : today,
    freight_forwarder:
      editedItem && "freight_forwarder" in editedItem
        ? editedItem.freight_forwarder
        : "",
    estimated_delivery_date:
      editedItem &&
      "estimated_delivery_date" in editedItem &&
      editedItem.estimated_delivery_date !== null
        ? editedItem.estimated_delivery_date
        : today,

    total_weight:
      editedItem && "total_weight" in editedItem
        ? translateNumber(editedItem.total_weight)
        : "",
    v_weight:
      editedItem && "v_weight" in editedItem
        ? translateNumber(editedItem.v_weight)
        : "",
    manifest: editedItem && "manifests" in editedItem ? manifestIds : [],
  })

  console.log(datas);

  const events = useSelector((state) => state.EventReducer.event)
  const courier = useSelector((state) => state.CourierReducer.courier)
  const error = useSelector((state) => state.parcelReducer.errorMessage)
  const courierError = useSelector((state) => state.CourierReducer.error)
  const eventError = useSelector((state) => state.ordersReducer.eventError)
  const success = useSelector((state) => state.parcelReducer.successMessage)
  const manifestList = useSelector((state) => state.ManifestReducer.manifest)
  const updateError = useSelector((state) => state.parcelReducer.updateError)
  const courierSuccess = useSelector((state) => state.CourierReducer.success)
  const deleteError = useSelector((state) => state.CourierReducer.deleteError)
  const eventSuccess = useSelector((state) => state.ordersReducer.eventMessage)
  const updateSuccess = useSelector(
    (state) => state.parcelReducer.updateSuccess
  )
  const deleteSuccess = useSelector(
    (state) => state.CourierReducer.deleteSuccess
  )

  useEffect(() => {
    dispatch(getCompanies())
  }, [])

  const handlehnagestatus = (value) => {
    setSelectedStatus(value)
  }

  const filteredEvents = events.map((item) => {
    return (
      <Option
        value={item.event_code}
      >{`${item.event_code} ${item.event_description}`}</Option>
    )
  })

  const dispatch = useDispatch()

  eventSuccess &&
    setTimeout(() => {
      setActive(false)
    }, 1000)

  success &&
    setTimeout(() => {
      setActive(false)
    }, 1000)

  updateSuccess &&
    setTimeout(() => {
      setActive(false)
    }, 1000)

  const data = []

  useEffect(() => {
    if (type == "delivery") {
      dispatch(getCourier())
    }
    if (type == "branches" || type == "company" || type == "order") {
      dispatch(getEvent())
    }
  }, [dispatch, type, deleteSuccess, deleteError, courierSuccess, courierError])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }, [success])

  const handleChange = (value) => {
    data.push(value)
    setDatas({ ...datas, manifest: value })
  }

  const onChange = (e) => {
    const { name, value } = e.target
    setDatas({ ...datas, [name]: value })
  }

  const onChangeWayDate = (date, dateString) => {
    setDatas({ ...datas, in_my_way_date: dateString })
  }

  const onChangeDeliveryDate = (date, dateString) => {
    setDatas({ ...datas, estimated_delivery_date: dateString })
  }

  const deleteCourier = (id) => {
    setConfirmModal(!confirmModal)
    dispatch(removeCourier(id, courier))
  }

  const confirm = (name, id) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete "${name}"`,
      okText: "Ok",
      cancelText: "Cancel",
      onOk: () => deleteCourier(id),
    })
  }
  const couriersList =
    courier.length > 0 ? (
      courier.map((item) => {
        return (
          <div
            key={item.id}
            className={styles.main_modal__card__wrapper_list_item}
          >
            <p> {item.full_name + " " + item.phone}</p>
            <img
              src={circle_close}
              alt="close"
              onClick={() => confirm(item.full_name, item.id)}
            />
          </div>
        )
      })
    ) : (
      <p className={styles.main_modal__card__wrapper_list_item_empty}>
        No data
      </p>
    )

  const optionDatas = manifestList.map((item) => {
    return (
      <Option key={item.id} value={item.id}>
        {item.name}
      </Option>
    )
  })

  const [fields, errors, form] = useFormInputValidation(
    {
      full_name: "",
      phone: "",
    },
    {
      full_name: "required",
      phone: "required|between:6,15",
    }
  )

  const handleChangeCompany = (value) => {
    setDatas({ ...datas, company_id: value })
  }

  const onSubmit = async (event) => {
    const isValid = await form.validate(event)
    if (fields.full_name) {
      errors.full_name = ""
    }

    if (isValid) {
      addData({ full_name: fields.full_name, phone: fields.phone })
      fields.full_name = ""
      fields.phone = ""
    }
  }

  return type == "delivery" ? (
    <div className={styles.main_modal}>
      <div className={styles.main_modal__card} ref={modalRef}>
        <div className={styles.main_modal__card__wrapper}>
          {courierSuccess.length > 0 && message.success(courierSuccess)}
          {courierError && message.error(courierError)}
          <div className={styles.main_modal__card__wrapper_path}>
            {"Delivery / Couriers list"}
          </div>
          <form
            noValidate
            autoComplete="off"
            onSubmit={onSubmit}
            className={styles.main_modal__card__wrapper_form}
          >
            <div> Name</div>
            <div>Phone</div>
            <div></div>
            <div>
              <InputGroup
                onBlur={form.handleBlurEvent}
                onChange={form.handleChangeEvent}
                value={fields.full_name}
                type="text"
                name="full_name"
                placeholder="Name"
                className={styles.main_modal__card__wrapper_items_input}
              />

              <label
                className={` error ${styles.main_modal__card__wrapper_items_label}`}
              >
                {fields.full_name ? "" : errors.full_name}
              </label>
            </div>

            <div>
              <InputGroup
                type="tel"
                onBlur={form.handleBlurEvent}
                name="phone"
                onChange={form.handleChangeEvent}
                value={fields.phone}
                placeholder="+374 94 123456"
                className={styles.main_modal__card__wrapper_items_input}
              />

              <label
                className={` error ${styles.main_modal__card__wrapper_items_label}`}
              >
                {errors.phone ? errors.phone : ""}
              </label>
            </div>
            <ButtonGroup
              type="submit"
              className={styles.main_modal__card__wrapper_button}
            >
              Add Courier
            </ButtonGroup>
          </form>
          <div className={styles.main_modal__card__wrapper_list}>
            {couriersList}
          </div>
          <div className={styles.main_modal__card__wrapper_buttons_delivery}>
            <ButtonGroup
              onClick={handleCnacel}
              className={styles.main_modal__card__wrapper_button}
            >
              Close
            </ButtonGroup>
          </div>
        </div>
      </div>
    </div>
  ) : type == "branches" || type == "company" || type == "order" ? (
    <div className={styles.main_modal}>
      <Modal></Modal>
      <div className={styles.main_modal__card} ref={modalRef}>
        <div className={styles.main_modal__card__wrapper}>
          {eventSuccess.length > 0 && message.success(eventSuccess)}
          {eventError && message.error(eventError)}
          <div className={styles.main_modal__card__wrapper_path}>
            {"Select Event"}
          </div>

          <form action="">
            <label htmlFor="">Event Code And Name</label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Select
                className={styles.main_modal__card__wrapper__select}
                onChange={handlehnagestatus}
                defaultValue={
                  oneOrder?.current_event &&
                  `${oneOrder.current_event?.event?.event_code} ${oneOrder.current_event?.event?.event_description}`
                }
                placeholder={"Event"}
              >
                <OptGroup>{filteredEvents}</OptGroup>
              </Select>

              <ButtonGroup
                onClick={(e) => sentEvent(selectedStatus, e)}
                className={styles.main_modal__card__wrapper_button_sent}
              >
                Set Event
              </ButtonGroup>
            </div>
          </form>

          <div className={styles.main_modal__card__wrapper_buttons_delivery}>
            <ButtonGroup
              onClick={handleCnacel}
              className={styles.main_modal__card__wrapper_button}
            >
              Close
            </ButtonGroup>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.main_modal} ref={modalRef}>
      <div className={styles.main_modal__card}>
        <div className={styles.main_modal__card__wrapper}>
          {success && message.success(success)}
          {updateSuccess && message.success(updateSuccess)}

          <div className={styles.main_modal__card__wrapper_path}>
            {editedItem && Object.entries(editedItem).length > 0
              ? "Parcel / Update Parcel"
              : "Parcel / Create Parcel"}
          </div>

          <div className={styles.main_modal__card__wrapper_items}>
            <div>Partner</div>
            <div>Parsel Name</div>
            <div>Air way Bill</div>
            <div>In My Way date</div>
            <div className={styles.main_modal__card__wrapper_items_sl}>
              <Select
                defaultValue={
                  editedItem && "company" in editedItem
                    ? editedItem.company.name
                    : ""
                }
                onChange={handleChangeCompany}
              >
                {company.map(({ id, name }) => {
                  return (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  )
                })}
              </Select>
            </div>
            <div>
              <InputGroup
                onChange={onChange}
                value={datas.parcel_name}
                name="parcel_name"
                className={styles.main_modal__card__wrapper_items_input}
              />
              <label className={styles.main_modal__card__wrapper_items_label}>
                {error
                  ? error?.parcel_name
                  : updateError
                  ? updateError?.parcel_name
                  : ""}
              </label>
            </div>
            <div>
              <InputGroup
                value={datas.air_way_bill}
                onChange={onChange}
                name="air_way_bill"
                className={styles.main_modal__card__wrapper_items_input}
              />
              <label className={styles.main_modal__card__wrapper_items_label}>
                {error
                  ? error?.air_way_bill
                  : updateError
                  ? updateError?.air_way_bill
                  : ""}
              </label>
            </div>
            <div>
              <div className={styles.main_modal__card__wrapper_items_input}>
                <DatePicker
                  name="in_my_way_date"
                  defaultValue={
                    datas.in_my_way_date !== null &&
                    moment(new Date(datas.in_my_way_date), dateFormat)
                  }
                  onChange={onChangeWayDate}
                  format={dateFormat}
                />
              </div>
              <label className={styles.main_modal__card__wrapper_items_label}>
                {error
                  ? error?.in_my_way_date
                  : updateError
                  ? updateError?.in_my_way_date
                  : ""}
              </label>
            </div>
          </div>

          <div className={styles.main_modal__card__wrapper_items2}>
            <div>Freight forwarder</div>
            <div>Estimated Delivery date</div>
            <div>Total Weigth</div>
            <div>V Weigth</div>
            <div>
              <InputGroup
                value={datas.freight_forwarder}
                onChange={onChange}
                name="freight_forwarder"
                className={styles.main_modal__card__wrapper_items_input}
              />
              <label className={styles.main_modal__card__wrapper_items_label}>
                {error
                  ? error?.freight_forwarder
                  : updateError
                  ? updateError?.freight_forwarder
                  : ""}
              </label>
            </div>

            <div>
              <div className={styles.main_modal__card__wrapper_items_input}>
                <DatePicker
                  name="estimated_delivery_date"
                  onChange={onChangeDeliveryDate}
                  format={dateFormat}
                  defaultValue={moment(
                    new Date(datas.estimated_delivery_date),
                    dateFormat
                  )}
                />
              </div>
              <label className={styles.main_modal__card__wrapper_items_label}>
                {error
                  ? error?.estimated_delivery_date
                  : updateError
                  ? updateError?.estimated_delivery_date
                  : ""}
              </label>
            </div>

            <div>
              <InputGroup
                type="number"
                value={datas.total_weight}
                onChange={onChange}
                name="total_weight"
                className={styles.main_modal__card__wrapper_items_input}
              />
              <label className={styles.main_modal__card__wrapper_items_label}>
                {error
                  ? error?.total_weight
                  : updateError
                  ? updateError?.total_weight
                  : ""}
              </label>
            </div>

            <div>
              <InputGroup
                type="number"
                value={datas.v_weight}
                onChange={onChange}
                name="v_weight"
                className={styles.main_modal__card__wrapper_items_input}
              />
              <label className={styles.main_modal__card__wrapper_items_label}>
                {error
                  ? error?.v_weight
                  : updateError
                  ? updateError?.v_weight
                  : ""}
              </label>
            </div>
          </div>
          <div>
            <div className={styles.main_modal__card__wrapper_select}>
              <Select
                name="manifest"
                showSearch
                className={styles.main_modal__card__wrapper_select_item}
                mode="multiple"
                suffixIcon={<img src={arrow_down} alt="arrow down" />}
                showArrow
                style={{
                  width: "100%",
                }}
                removeIcon={<img src={close} />}
                placeholder="Select Manifests"
                defaultValue={() => datas.manifest.map((item) => item.name)}
                multiselect
                onChange={handleChange}
              >
                {optionDatas}
              </Select>
            </div>
            <label className={styles.main_modal__card__wrapper_items_label}>
              {error
                ? error?.manifest
                : updateError
                ? updateError?.manifest
                : ""}
            </label>
          </div>

          <div className={styles.main_modal__card__wrapper_buttons}>
            <ButtonGroup
              onClick={handleCnacel}
              className={styles.main_modal__card__wrapper_button}
            >
              Cancel
            </ButtonGroup>

            <ButtonGroup
              onClick={() => createParcel(datas)}
              className={styles.main_modal__card__wrapper_button}
            >
              {editedItem && Object.entries(editedItem).length > 0
                ? "Update"
                : "Create"}
            </ButtonGroup>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalGroup
