import React, {useEffect, useState} from "react"
import {Select, Button, Form, message, Popconfirm} from "antd"
import {useNavigate} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"

import styles from "./branches.module.scss"

import search from "../../assets/images/search.svg"
import {getEvent} from "../../store/actions/EventsAction"
import {distributeOrders, getOrders} from "../../store/actions"
import {
    filterOrders,
    searchOrder,
    setNewEvent,
    getOrdersDetails,
    getCompanies,
} from "../../store/actions"

import {
    ImageGroup,
    TableItem,
    InputGroup,
    ModalGroup,
    ButtonGroup,
    OptionDetails,
    PaginationGroup,
    DataPickerGroup,
} from "../../components/index"
import InputSelect from "../../components/elements/InputSelect";
import {fetchSupportDetails, receiveOrders} from "../../store/actions/SupportAction";
import {Eye} from "../../assets/svg-component/Eye";
import {SortingButtons} from "../../assets/svg-component/SortingButtons";
import {InputSimple} from "../../components/elements";
import {debounceHook} from "../../hooks/useDebounce";
import AntDataTable from "../../components/AntTable";
import {SelectForTable} from "../../components/elements/SelectForTable";
import styled from "styled-components";


const {Option} = Select
const debouncedFilter = debounceHook((fn) => fn(), 500);
const StyledSelect = styled.div `
  margin-left: 50px;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
    border: ${({hover}) => hover && "none"};
    background: ${({bg}) => bg || "#ffffff"};
    color: ${({color}) => color || "#262626"};
    font-family: 'Montserrat';

    &:focus,
    &:hover,
    &:active {
      border-color: #5dba2f;
      box-shadow: #5dba2f;
      border-right-width: 1px;
      outline: 0;
    }
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
    border-color: #5dba2f;
    box-shadow: 0 0 0 2px rgb(93 186 47 / 20%);
    border-right-width: 1px;
    outline: 0;
  }
`
const Branches = () => {
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(100)
    const [active, setActive] = useState(false)
    const [oneOrder, setOneOrder] = useState({})
    const [filterData, setFilterData] = useState({})
    const [companyValue, setCompanyValue] = useState({})
    const [selectedData, setSelectedData] = useState("")
    const [parcelId, setParcelId] = useState("")
    const [openOptionDetails, setOpenOptionDetails] = useState(false)
    const [inputValue, setInputValue] = useState({})
    const isActive = useSelector((state) => state.ordersReducer.bool)
    const orders = useSelector((state) => state.ordersReducer.orders)
    const order = useSelector((state) => state.ordersReducer.oneOrder)
    const company = useSelector((state) => state.ordersReducer.company)
    const allOrders = useSelector((state) => state.ordersReducer.allOrders)
    const parcelsList = useSelector((state) => state.parcelReducer.parcel);
    const [sorter, setSorter] = useState();
    const {data = [], load, allData} = useSelector((state) => state.support);
    const [isActiveOptionDetails, setIsActiveOptionDetails] = useState(false);
    const [filterValue, setFilterValue] = useState("");
    const [filterFields, setFilterFields] = useState({});

    const distributeError = useSelector(
        (state) => state.ordersReducer.errorDistribute
    )
    const distributeSuccess = useSelector(
        (state) => state.ordersReducer.successDistribute
    )

    const isLoggedIn = localStorage.getItem("accessToken")
    const user = JSON.parse(localStorage.getItem("userInfo"))

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const totalOrders = allOrders?.meta?.total
    let selectData = [...parcelsList].map(name => ({
        ket: name.id,
        title: name.parcel_name,
        value: name.id

    }))
    useEffect(() => {
        if (isLoggedIn == null) {
            navigate("/login")
        }
    }, [isLoggedIn, navigate, user])

    useEffect(() => {
        if (Object.entries(companyValue).length == 0) {
            dispatch(fetchSupportDetails(filterFields));
        } else {
            filterFields['company_id'] = companyValue.company_id;
            dispatch(fetchSupportDetails(filterFields));
            // dispatch(getOrders(perPage, page, companyValue))
        }
    }, [companyValue])

    useEffect(() => {
        dispatch(getCompanies());
        dispatch(fetchSupportDetails({per_page: perPage, page: page}));
    }, [])

    const handleChangeParcelName = (val) => {
        setPage(1)
        setPerPage(15)
        setInputValue({parcel_id: val})
        let parcelIds = [];

        if (val) {
            filterFields['parcels[]'] = val;
            dispatch(fetchSupportDetails(filterFields))
        } else {
            delete filterFields['parcels[]'];
            dispatch(fetchSupportDetails(filterFields))
        }

    }
    const handleChangecompany = (value, e) => {
        const {name} = e
        setCompanyValue(name !== "all" ? {company_id: value} : {})
    }

    const handleOpenModal = (id) => {
        const oneOrder = orders.find((item) => item.id == id)
        setOneOrder(oneOrder)
        setActive(!active)
    }

    const handleClick = (id) => {
        dispatch(getOrdersDetails(id))
        dispatch(getEvent())
        setOpenOptionDetails(!openOptionDetails)
    }

    const onChangeDate = (data, dateString) => {
        // setFilterData({start_at: dateString[0], end_at: dateString[1]})
        filterFields['start_at'] = dateString[0];
        filterFields['end_at'] = dateString[1];
        dispatch(fetchSupportDetails(filterFields));
    }

    useEffect(() => {
        if (Object.entries(filterData).length > 0) {
            dispatch(filterOrders(10, 1, filterData, ""))
        }
    }, [filterData])

    const handleCnacel = () => {
        setActive(false)
    }

    const changePerPage = (item, value) => {
        console.log(item);
        setPage(item)
        setPerPage(value)
        const newParams = {};
        filterFields['per_page'] = value;
        filterFields['page'] = item;
        // Object.entries(inputValue).forEach(([key, value]) => {
        //     if (value) {
        //         newParams[key] = value;
        //     }
        // })

        dispatch(fetchSupportDetails(filterFields));
        // dispatch(getOrders(perPage, value))
    }

    const sentEvent = (event_code, e) => {
        e.preventDefault()

        dispatch(setNewEvent({order_id: oneOrder.id, event_code}, orders))
    }

    const handleChangeSelect = (e) => {
        setSelectedData(e.target.value)
    }

    const handleSearch = () => {
        dispatch(searchOrder({tracking_number: selectedData}))
    }

    const hamdleDistribute = () => {
        dispatch(distributeOrders({parcel_id: parcelId}))
    }

    const enterSearchInput = (e, field, type = "input") => {
        // setFilterFields({...filterFields, filterFields[field] = });
        debouncedFilter(() => {
            if (e.target.value !== "") {
                filterFields[field] = e.target.value;
            } else {
                delete filterFields[field];
            }
            handleSearchOrder();
        });

        if (type === "select") {
            if (e !== "") {
                filterFields[field] = e;
            } else {
                delete filterFields[field];
            }
            handleSearchOrder();
        }
    };


    const handleChangeFilterField = (e) => {
        setFilterValue(e.target.value);
        if (e.target.value === "") {
            handleSearchOrder();
        }
    };

    const handleSearchOrder = () => {
        if (Object.keys(filterFields).length === 0) {
            dispatch(fetchSupportDetails(perPage, page));
        } else {
            delete filterFields['page'];
            dispatch(fetchSupportDetails(filterFields));
            setPerPage(100);
            setPage(1);
        }
    };

    const handleReceive = (id) => {
        dispatch(receiveOrders([id]));
        filterFields['per_page'] = 100;
        dispatch(fetchSupportDetails(filterFields));
    }

    const filteredData = [...data].map(
        (
            {
                id,
                receiver_full_name,
                receiver_phone,
                receiver_address_line1,
                delivery_type,
                pickup_point,
                receiver_city,
                receiver_state,
                tracking_number,
                order_number,
                current_event,
                parcel,
                receiver_country,
                status,
                parcel_weight,
                parcel_length,
                parcel_height,
                parcel_width,
                shipped_date,
                id_country,
                id_number,
                id_type,
                destination_status,
                destination_type
            }, i) => {
            return {
                id,
                receiver_full_name,
                receiver_phone,
                receiver_address_line1,
                delivery_type,
                pickup_point: `${pickup_point?.address !== undefined ? pickup_point.address : ''} ${pickup_point?.description !== undefined ? pickup_point?.description : ''}`,
                receiver_city,
                receiver_state,
                tracking_number,
                order_number,
                parcel: parcel?.parcel_name,
                receiver_country,
                deliveryStatus: status,
                parcel_weight,
                domentions: `Height: ${parcel_height}, Length: ${parcel_length}, Width: ${parcel_width}`,
                shipped_date,
                id_country,
                id_number,
                id_type,
                current_event: current_event?.event_description,
                destination_status,
                destination_type
            }
        }
    );


    let dataSource = filteredData;

    if (sorter) {
        dataSource = [...filteredData].sort((a, b) => {
            if (a.id > b.id) {
                return sorter === "+" ? 1 : -1;
            }
            if (a.id < b.id) {
                return sorter === "+" ? -1 : 1;
            }
            return 0;
        });
    }

    const handleShowDetails = (id) => {
        dispatch(getOrdersDetails(id));
        dispatch(getEvent());
        setOpenOptionDetails(true);
    };


    const columns = [
        {
            title: "",
            dataIndex: "icon",
            key: "icon",
            render: (_, record) => (
                <Eye key={record.id} onClick={() => handleShowDetails(record.id)}/>
            ),
            width: 32,
            fixed: "left",
        },
        {
            dataIndex: "id",
            title: () => (
                <div className={""}>
          <span className={"title_text"}>
            ID{" "}
              <SortingButtons
                  color="#757272"
                  handleChange={(direction) => {
                      setSorter((sorter) => (sorter === direction ? "" : direction));
                  }}
              />
          </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "id")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "id",
            title: () => (
                <div className={""}>
          <span className={"title_text"}>
            Delivery Status{" "}
              <SortingButtons
                  color="#757272"
                  handleChange={(direction) => {
                      setSorter((sorter) => (sorter === direction ? "" : direction));
                  }}
              />
          </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "id")}
                    />
                </div>
            ),
            render: (_, record) => (
                    record.deliveryStatus === 'delivered' ? 'Delivered' : (
                       <Popconfirm title={"Are you sure?"} onConfirm={() => handleReceive(record.id)}>
                           <Button className={styles.main_branches__wrapper_button_distribute} type={'primary'}
                                   >
                               Receive
                           </Button>
                       </Popconfirm>
                    )
            )
        },
        {
            dataIndex: "receiver_full_name",
            title: () => (
                <div className={""}>
          <span className={"title_text"}>
            Full Name{" "}
              <SortingButtons
                  color="#757272"
                  handleChange={(direction) => {
                      setSorter((sorter) => (sorter === direction ? "" : direction));
                  }}
              />
          </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "receiver_full_name")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "receiver_phone",
            title: () => (
                <div className={""}>
          <span className={"title_text"}>
            Phone{" "}
              <SortingButtons
                  color="#757272"
                  handleChange={(direction) => {
                      setSorter((sorter) => (sorter === direction ? "" : direction));
                  }}
              />
          </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "receiver_phone")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "receiver_address_line1",
            title: () => (
                <div className={""}>
          <span className={"title_text"}>
            Address line 1{" "}
              <SortingButtons
                  color="#757272"
                  handleChange={(direction) => {
                      setSorter((sorter) => (sorter === direction ? "" : direction));
                  }}
              />
          </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "receiver_address_line1")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "delivery_type",
            title: () => (
                <div className={""}>
          <span className={"title_text"}>
            Delivery Type{" "}
              <SortingButtons
                  color="#757272"
                  handleChange={(direction) => {
                      setSorter((sorter) => (sorter === direction ? "" : direction));
                  }}
              />
          </span>
                    <SelectForTable
                        options={["hayPost", "homeDelivery", "PUDO"]}
                        enterSearchInput={enterSearchInput}
                        fieldName={"delivery_type"}
                    />
                </div>
            ),
        },
        {
            dataIndex: "pickup_point",
            title: () => (
                <div className={""}>
          <span className={"title_text"}>
            Pickup Point{" "}
              <SortingButtons
                  color="#757272"
                  handleChange={(direction) => {
                      setSorter((sorter) => (sorter === direction ? "" : direction));
                  }}
              />
          </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "pudo_reference")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "receiver_city",
            title: () => (
                <div className={""}>
          <span className={"title_text"}>
            City{" "}
              <SortingButtons
                  color="#757272"
                  handleChange={(direction) => {
                      setSorter((sorter) => (sorter === direction ? "" : direction));
                  }}
              />
          </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "receiver_city")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "receiver_state",
            title: () => (
                <div className={""}>
          <span className={"title_text"}>
            State{" "}
              <SortingButtons
                  color="#757272"
                  handleChange={(direction) => {
                      setSorter((sorter) => (sorter === direction ? "" : direction));
                  }}
              />
          </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "receiver_state")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "tracking_number",
            title: () => (
                <div className={""}>
          <span className={"title_text"}>
            Tracking No{" "}
              <SortingButtons
                  color="#757272"
                  handleChange={(direction) => {
                      setSorter((sorter) => (sorter === direction ? "" : direction));
                  }}
              />
          </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "tracking_number")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "order_number",
            title: () => (
                <div className={""}>
          <span className={"title_text"}>
            Order No{" "}
              <SortingButtons
                  color="#757272"
                  handleChange={(direction) => {
                      setSorter((sorter) => (sorter === direction ? "" : direction));
                  }}
              />
          </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "order_number")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "current_event",
            title: () => (
                <div className={""}>
          <span className={"title_text"}>
            Delivery Status{" "}
              <SortingButtons
                  color="#757272"
                  handleChange={(direction) => {
                      setSorter((sorter) => (sorter === direction ? "" : direction));
                  }}
              />
          </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "current_event")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "parcel",
            title: () => (
                <div className={""}>
          <span className={"title_text"}>
            Parcel Name{" "}
              <SortingButtons
                  color="#757272"
                  handleChange={(direction) => {
                      setSorter((sorter) => (sorter === direction ? "" : direction));
                  }}
              />
          </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "parcel")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "receiver_country",
            title: () => (
                <div className={""}>
          <span className={"title_text"}>
            Country{" "}
              <SortingButtons
                  color="#757272"
                  handleChange={(direction) => {
                      setSorter((sorter) => (sorter === direction ? "" : direction));
                  }}
              />
          </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "receiver_country")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "status",
            title: () => (
                <div className={""}>
          <span className={"title_text"}>
            Status{" "}
              <SortingButtons
                  color="#757272"
                  handleChange={(direction) => {
                      setSorter((sorter) => (sorter === direction ? "" : direction));
                  }}
              />
          </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "status")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "parcel_weight",
            title: () => (
                <div className={""}>
          <span className={"title_text"}>
            Parcel Weight{" "}
              <SortingButtons
                  color="#757272"
                  handleChange={(direction) => {
                      setSorter((sorter) => (sorter === direction ? "" : direction));
                  }}
              />
          </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "parcel_weight")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "domentions",
            title: () => (
                <div className={""}>
          <span className={"title_text"}>
            Domentions{" "}
              <SortingButtons
                  color="#757272"
                  handleChange={(direction) => {
                      setSorter((sorter) => (sorter === direction ? "" : direction));
                  }}
              />
          </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "domentions")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "shipped_date",
            title: () => (
                <div className={""}>
          <span className={"title_text"}>
            Shipped Date{" "}
              <SortingButtons
                  color="#757272"
                  handleChange={(direction) => {
                      setSorter((sorter) => (sorter === direction ? "" : direction));
                  }}
              />
          </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "shipped_date")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "id_country",
            title: () => (
                <div className={""}>
          <span className={"title_text"}>
            ID Country{" "}
              <SortingButtons
                  color="#757272"
                  handleChange={(direction) => {
                      setSorter((sorter) => (sorter === direction ? "" : direction));
                  }}
              />
          </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "id_country")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "id_number",
            title: () => (
                <div className={""}>
          <span className={"title_text"}>
            ID Number{" "}
              <SortingButtons
                  color="#757272"
                  handleChange={(direction) => {
                      setSorter((sorter) => (sorter === direction ? "" : direction));
                  }}
              />
          </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "id_number")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "id_type",
            title: () => (
                <div className={""}>
          <span className={"title_text"}>
            ID Type{" "}
              <SortingButtons
                  color="#757272"
                  handleChange={(direction) => {
                      setSorter((sorter) => (sorter === direction ? "" : direction));
                  }}
              />
          </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "id_type")}
                    />
                </div>
            ),
        },
    ]

    return (
        <section className={styles.main_branches}>
            <OptionDetails
                isopenModal={openOptionDetails}
                setIsOpenModal={setOpenOptionDetails}
                item={order}
            />
            {active && (
                <ModalGroup
                    active={active}
                    setActive={setActive}
                    type="branches"
                    oneOrder={oneOrder}
                    handleCnacel={handleCnacel}
                    sentEvent={sentEvent}
                />
            )}
            <div
                className={`container ${
                    isActive
                        ? `main_branches_mobile_active ${styles.main_branches__wrapper_active}`
                        : `main_branches_mobile ${styles.main_branches__wrapper}`
                }`}
            >
                {distributeSuccess.length > 0 && message.success(distributeSuccess)}
                {distributeError && message.error(distributeError)}

                <br/>
                <Form className={styles.main_branches__wrapper_items}>
                    <div className={styles.main_branches__wrapper_items_left}>
                        <InputGroup
                            value={selectedData}
                            onChange={handleChangeSelect}
                            placeholder="Tracking code"
                            className={styles.main_branches__wrapper_select}
                        />
                        <Button
                            onClick={handleSearch}
                            className={styles.main_branches__wrapper_button}
                        >
                            <ImageGroup src={search}></ImageGroup>
                        </Button>
                        {/*<InputSelect width={"250px"} margin={"0 20px 0 20px"} data={selectData} placeholder={"Parcel Name"}*/}
                        {/*             obj={true} onChange={(val) => handleChangeParcelName(val)}/>*/}
                        <StyledSelect>
                        <Select mode={"multiple"} style={{width: 200, marginRight: 30, borderRadius: "0.5rem"}} placeholder={"Parcel Name"} onChange={(val) => handleChangeParcelName(val)}>
                            {
                                selectData.map((item, key) => {
                                    return <Option value={item.ket}>{item.title}</Option>
                                })
                            }
                        </Select>
                        </StyledSelect>
                    </div>

                    <div className={styles.main_branches__wrapper_sel}>
                        <Select defaultValue={"All"} onChange={handleChangecompany}>
                            <Option value="" name={"all"}>
                                All
                            </Option>
                            {company.map(({id, name}) => {
                                return (
                                    <Option key={id} value={id} name={name}>
                                        {name}
                                    </Option>
                                )
                            })}
                        </Select>
                    </div>
                    <ButtonGroup
                        onClick={hamdleDistribute}
                        className={styles.main_branches__wrapper_button_distribute}
                        disabled={parcelId === ""}
                    >
                        Distribute
                    </ButtonGroup>
                    <div className={styles.main_branches__wrapper_date}>
                        <DataPickerGroup onChange={onChangeDate}/>
                    </div>
                </Form>
                <AntDataTable
                    columns={columns}
                    dataSource={dataSource}
                    loading={load}
                    perPage={perPage}
                    page={page}
                    changePerPage={changePerPage}
                    total={allData}
                    wrapper={false}
                    pagination={true}
                />
            </div>
        </section>
    )
}

export default Branches
