import {Table} from "antd";
import {useSelector} from "react-redux";
import styled from "styled-components";
import styles from "../TableItem/table.module.scss";
import {PaginationGroup} from "../../components/index";

const StyledAntDataTable = styled.div`
  margin-right: 26px;
  margin-left: 25px;

  .bg-destination {
    background: #729f9f2b;
  }

  .ant-table .ant-table-container::before,
  .ant-table .ant-table-container::after {
    width: 50px !important;
  }

  .pickup_point {
    display: -webkit-box;
    overflow: hidden;
    max-height: 44px;
    text-overflow: ellipsis;
    transition: all 0.2s;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const AntDataTable = ({
                          columns, dataSource, loading, perPage, page, changePerPage, total, wrapper, rowSelection,
                          scrollX,
                          pagination
                      }) => {
    const {successDistribute, errorDistribute, oneOrder, bool} = useSelector((state) => state.ordersReducer);
    const isActive = bool;

    return (<div className={styles.main_branches}>
        <div
            className={wrapper ? `container ${isActive ? `main_branches_mobile_active  ${styles.main_branches__wrapper_active}` : `main_branches_mobile ${styles.main_branches__wrapper}`}` : ''}
        >
            <StyledAntDataTable>
                <Table
                    columns={columns}
                    // rowSelection={"checkbox"}
                    rowSelection={rowSelection}
                    dataSource={dataSource}
                    scroll={{
                        x: scrollX ? scrollX : 2800, y: 1000 - 300,
                    }}
                    tableLayout="fixed"
                    className={"table"}
                    loading={loading}
                    pagination={false}
                    rowClassName={(record, index) => {
                        // eslint-disable-next-line no-unused-expressions
                        return record.destination_status === 110 && record.destination_type === null ? "bg-destination" : "bg-green"
                    }}
                />
                {
                    pagination ? (
                        <div className={styles.pagination}
                             style={{marginTop: '25px', display: 'flex', justifyContent: 'flex-end'}}>
                            <PaginationGroup
                                onChange={changePerPage}
                                page={page}
                                total={total?.meta?.total}
                                pageSize={perPage}
                            />
                        </div>
                    ) : null
                }
            </StyledAntDataTable>
        </div>
    </div>);
};

export default AntDataTable;
