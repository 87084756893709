import { AxiosInstance } from "../../utils/AxiosInstance";
import {
  FILTERED_DATAS,
  SET_ORDERS,
  SET_ORDERS_ERROR,
  SET_ORDERS_DETAILS,
  SEARCH_ORDERS,
  SET_BOOL,
  SET_NEW_EVENT,
  GET_COMPANY,
  SET_NEW_EVENT_ERROR, FETCH_CARGO,
} from "../types";

export const getOrders = (perPage, page, companyValue) => async (dispatch) => {
  try {
    const { data } = await AxiosInstance.get("admin/orders", {
      params: {
        per_page: perPage,
        page: page,
        ...companyValue
      },
    });
    dispatch({
      type: SET_ORDERS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: SET_ORDERS_ERROR,
      payload: "Invalid Token!",
    });

    setTimeout(() => {
      dispatch({
        type: SET_ORDERS_ERROR,
        payload: "",
      });
    }, 5000);
  }
};

export const getOrdersDetails = (id) => async (dispatch) => {
  try {
    const { data } = await AxiosInstance.get(`admin/orders/${id}`);
    dispatch({
      type: SET_ORDERS_DETAILS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: SET_ORDERS_ERROR,
      payload: "Something went wrong!",
    });
    dispatch({
      type: SET_ORDERS_ERROR,
      payload: "",
    });
  }
};

export const searchOrder = (searchData) => async (dispatch) => {
  try {
    const { data } = await AxiosInstance.get(`admin/orders`, {
      params: {
        ...searchData,
      },
    });
    dispatch({
      type: SEARCH_ORDERS,
      payload: data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getOrderByManifestId = (id) => async (dispatch) => {
  try {
    const { data } = await AxiosInstance.get(`admin/orders`, {
      params: {
        manifest_id: id,
      },
    });
    dispatch({
      type: SET_ORDERS,
      payload: data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const setBool = (data) => async (dispatch) => {
  await dispatch({
    type: SET_BOOL,
    payload: data,
  });
};

export const filterOrders =
  (perPage, page, filterDate, format) => async (dispatch) => {
    try {
      const { data } = await AxiosInstance.get("admin/orders", {
        // params: [
        //   // { per_page: perPage },
        //   // { page: page },
        //   `start_at=>${filterDate.start_at}`,
        //   `end_at=>${filterDate.end_at}`,
        //   `format=>${format}`,
        // ],
        params: {
          per_page: perPage,
          page: page,
          start_at: filterDate.start_at,
          end_at: filterDate.end_at,
          format,
        },
      });

      dispatch({
        type: FILTERED_DATAS,
        payload: data.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

export const setNewEvent = (eventData, orders) => async (dispatch) => {
  try {
    const { data } = await AxiosInstance.post(
      `admin/orders/set-event/${eventData.order_id}`,
      eventData
    );
    const indx = orders.findIndex((item) => item.id == data.data.id);
    orders.splice(indx, 1, data.data);

    dispatch({
      type: SET_NEW_EVENT,
      payload: { orders, data, message: "Event code is successfully updated!" },
    });

    setTimeout(() => {
      dispatch({
        type: SET_NEW_EVENT,
        payload: { orders, data, message: "" },
      });
    }, 2000);
  } catch (error) {
    console.log(error);

    dispatch({
      type: SET_NEW_EVENT_ERROR,
      payload: error?.response?.data?.error?.event_id,
    });
    setTimeout(() => {
      dispatch({
        type: SET_NEW_EVENT_ERROR,
        payload: "",
      });
    }, 1000);
  }
};

export const distributeOrders = (parcelId) => async (dispatch) => {
  try {
    const { data } = await AxiosInstance.post(`admin/orders/distribute`,parcelId);
    dispatch({
      type: "DISTRIBUTE_ORDERS",
      payload: "Success!",
    });

    setTimeout(() => {
      dispatch({
        type: "DISTRIBUTE_ORDERS",
        payload: "",
      });
    }, 2000);
  } catch (error) {
    dispatch({
      type: "DISTRIBUTE_ORDERS_ERROR",
      payload: "Something went wrong",
    });

    setTimeout(() => {
      dispatch({
        type: "DISTRIBUTE_ORDERS_ERROR",
        payload: "",
      });
    }, 2000);
  }
};

export const getCompanies = () => async(dispatch) => {
  try {
    const {data} = await AxiosInstance.get(`admin/company`);
    dispatch({
      type:GET_COMPANY,
      payload:data.data
    })
  } catch (error) {
    console.log(error);
  }
}

export const getCargoData = (compareData) => async(dispatch) => {
  try {
    const {data} = await AxiosInstance.post(`admin/orders/cargo-status/${compareData.id}`, {is_cargo_type: compareData.type});
    dispatch({
      type: FETCH_CARGO,
      payload: data.data
    })
  } catch (error) {
    console.log(error)
  }
}