import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./support.module.scss";
import { getOrdersDetails} from "../../store/actions";


import {
  DataPickerGroup,
  OptionDetails,
  PaginationGroup,
} from "../../components/index";
import AntTable from "../../components/TableItem/ant-table";
import { fetchSupportDetails } from "../../store/actions/SupportAction";
import InputSelect from "../../components/elements/InputSelect";


const Support = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [openOptionDetails, setOpenOptionDetails] = useState(false);
  const parcelsList = useSelector((state) => state.parcelReducer.parcel);
  const {allData={},load} = useSelector((state) => state.support);
  const {meta={}} = allData
  const totalOrders = meta?.total;
  const [perPage, setPerPage] = useState(meta?.per_page ?meta?.per_page : 20 );
  const {successDistribute,errorDistribute, oneOrder,bool} = useSelector((state) => state.ordersReducer);
  const isActive =bool;
  const isLoggedIn = localStorage.getItem("accessToken");
  const user = JSON.parse(localStorage.getItem("userInfo"));

  const [filterField, setFilterField] = useState({
    id: null,
    delivery_type: null,
    receiver_full_name: null,
    receiver_phone: null,
    receiver_address_line1: null,
    receiver_city: null,
    pickup_point: null,
    receiver_state: null,
    tracking_number: null,
    status: null,
    current_event: null,
    parcel_weight: null,
    parcel_height: null,
    parcel_length: null,
    parcel_width: null,
    shipped_date: null,
    parcel: null,
    receiver_zip:null,
    parcel_id:null
  });

  useEffect(() => {
    if (isLoggedIn == null) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate, user]);

  useEffect(() => {
    dispatch(fetchSupportDetails());
  }, []);

const handleShow = (id)=>{
  dispatch(getOrdersDetails(id))
  setOpenOptionDetails(!openOptionDetails)
}

  const handleChangeParcelName= (val)=>{
    setPage(1)
    setPerPage(15)
    setFilterField({ ...filterField, parcel_id:val })
    if(val){
      dispatch(fetchSupportDetails({parcel_id:val}))
    }else {
      dispatch(fetchSupportDetails({}))
    }

  }


  const changePerPage = (item, value) => {
    const newParams = {};
    Object.entries(filterField).forEach(([key, value]) => {
      if (value) {
        newParams[key] = value;
      }})
    setPage(item);
    setPerPage(value)
    let pageList = { page: item,
      per_page: value,
      ...newParams
    }
      dispatch(fetchSupportDetails( pageList));
  };

  return (
    <section className={styles.main_branches}>
      <OptionDetails
        isopenModal={openOptionDetails}
        setIsOpenModal={setOpenOptionDetails}
        item={oneOrder}
      />
      <div
        className={`container ${
          isActive
            ? `main_branches_mobile_active  ${styles.main_branches__wrapper_active}`
            : `main_branches_mobile ${styles.main_branches__wrapper}`
        }`}
      >
        {successDistribute.length > 0 && message.success(successDistribute)}
        {errorDistribute && message.error(errorDistribute)}


        <AntTable handleShow={handleShow} filterParcelName={filterField.parcel_id} setFilterField={setFilterField} setPage={setPage} setPerPage={setPerPage} />

        <div className={styles.pagination}>
          <PaginationGroup
            onChange={changePerPage}
            page={page}
            total={totalOrders}
             pageSize={perPage}
          />
        </div>
      </div>
    </section>
  );
};

export default Support;
