import { Div, P, Image, Flex } from "../../components/elements"
import close from "../../assets/images/close_circle.svg"
import { Button, Modal, Space } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"

const { confirm } = Modal

const CardItems = ({ editable, data }) => {
  const showConfirm = () => {
    confirm({
      title: "Do you Want to delete these items?",
      icon: <ExclamationCircleOutlined />,
      content: "Some descriptions",
      onOk() {
        console.log("Ok")
      },
      onCancel() {
        console.log("Cancel")
      },
    })
  }

  const users = data.map(({ id, name }) => {
    return (
      <Div
        key={id}
        witth="100%"
        border_b="1px solid  #F5F5F5"
        padding="0 0 13px 0"
      >
        <Flex display="flex" j_content="space-between">
          <P>{name}</P>
          {editable && <Image src={close} onClick={showConfirm} />}
        </Flex>
      </Div>
    )
  })

  return <>{users}</>
}

export default CardItems
