import {
  CREATE_COURIER,
  CREATE_COURIER_ERROR,
  SET_COURIER,
  REMOVE_COURIER_ERROR,
  REMOVE_COURIER,
} from "../types";

const initialState = {
  courier: [],
  success: "",
  error: "",
  deleteError: "",
  deleteSuccess: "",
};

export const CourierReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COURIER:
      return {
        ...state,
        courier: action.payload.data,
      };
    case CREATE_COURIER:
      return {
        ...state,
        courier: action.payload.newData,
        success: action.payload.message,
      };

    case CREATE_COURIER_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case REMOVE_COURIER:
      return {
        ...state,
        courier: action.payload.newData,
        deleteSuccess: action.payload.message,
      };
    case REMOVE_COURIER_ERROR:
      return {
        ...state,
        deleteError: action.payload,
      };
    default:
      return state;
  }
};
