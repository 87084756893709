import {
  FETCH_SUPPORT_DATA,
  FETCH_SUPPORT_DATA_ERROR,
  FETCH_SUPPORT_DATA_SUCCESS,
  UPDATE_SUPPORT_DATA_ERROR,
  SET_ORDERS, ADD_COURIER_TO_ORDERS_SUCCESS,
} from "../types";

const initialState = {
  allData: [],
  data:[],
  load: false,
  success: false,
  error: false,
};

export const supportReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUPPORT_DATA:
      return {
        ...state,
        load: true,
      };
    case FETCH_SUPPORT_DATA_SUCCESS:
      return {
        ...state,
        load: false,
        allData: action.payload,
        data:action.payload.data,
        success: true,
      };
    case UPDATE_SUPPORT_DATA_ERROR:
      return {
        ...state,
        load: false,
        data:action.payload,
        success: true,
      };
    case FETCH_SUPPORT_DATA_ERROR:
      return {
        ...state,
        load: false,
        error: true,
      };
    default:
      return state;
  }
};
