import React from "react";
import { Pagination } from "antd";

import styles from "./pagination.module.scss";

const PaginationGroup = ({
  total,
  page,
  onChange,
  pageSize,
}) => {
  return (
    <div className={styles.pagination__wrapper}>
      <Pagination
        current={page}
        total={total}
        pageSize={pageSize}
        onChange={onChange}
       pageSizeOptions={['15','30','50','100']}
        showTotal={(total1, range) => {
          return (
            <p className={styles.pagination_info}>
              {range[0]}-{range[1]} of {total1}
            </p>
          );
        }}
      />
    </div>
  );
};

export default PaginationGroup;
