import { useState } from "react"
import { useSelector } from "react-redux"
import { Modal, Space } from "antd"

import { trackInputDatas } from "../../utils/inputDatas"
import TableHaypost from "../../components/TableItem/TableHaypost"
import {
  Button,
  InputSimple,
  ContainerWrapper,
  Flex,
  Div,
  Card,
  P,
} from "../../components/elements/index"

import UserInfo from "./UserInfo"
import CardItems from "./CradItems"

const { confirm } = Modal

const data = [
  { id: 1, name: "Nazik Sukiasyan -1013" },
  { id: 2, name: "Nazik Sukiasyan -1013" },
  { id: 3, name: "Nazik Sukiasyan -1013" },
  { id: 4, name: "Nazik Sukiasyan -1013" },
  { id: 5, name: "Nazik Sukiasyan -1013" },
  { id: 6, name: "Nazik Sukiasyan -1013" },
  // { id: 11, name: "Nazik Sukiasyan -1013" },
  // { id: 21, name: "Nazik Sukiasyan -1013" },
  // { id: 31, name: "Nazik Sukiasyan -1013" },
  // { id: 41, name: "Nazik Sukiasyan -1013" },
  // { id: 51, name: "Nazik Sukiasyan -1013" },
  { id: 61, name: "Nazik Sukiasyan -1013" },
]

const HayPost = () => {
  const [active, setActive] = useState(false)

  const isActive = useSelector((state) => state.ordersReducer.bool)

  const trackInputs = trackInputDatas.map(
    ({ id, name, value, placeholder }) => {
      return (
        <InputSimple
          key={id}
          name={name}
          value={value}
          placeholder={placeholder}
          mr="25px"
        />
      )
    }
  )

  const handleRemove = (id) => {
    console.log(id)
    console.log("Thgis is for removing card items")
  }

  return (
    <ContainerWrapper
      height="100vh"
      padding={!isActive ? `26px 26px 0 67px` : `26px 26px 0 222px`}
      transition={
        !isActive
          ? `all 0.7s ease`
          : `all 0.7s ease;
            `
      }
    >
      {trackInputs}
      <Button
        color="#fff"
        bgc="#74BF4F"
        radius="8px"
        padding="5px 15px 5px 15px"
      >
        Find Track
      </Button>
      <Flex
        height="100vh"
        display="flex"
        j_content="space-around"
        align="center"
        padding="16px 0 0 0 "
      >
        <Div width="100%">
          <Flex display="flex" padding="0 0 0 18px">
            <P l_height="18px" f_size="12px" margin="0 10px 0 0 ">
              Weight: 0
            </P>
            <P l_height="18px" f_size="12px" margin="0 10px 0 0 ">
              Quantity: 0
            </P>
          </Flex>
          <Card
            radius="9px"
            bgc="#fff"
            height="378px"
            padding="18px"
            margin="8px 25px 16px 0 "
            o_y="auto"
          >
            <CardItems data={data} />
          </Card>
        </Div>
        <Div width="100%">
          <Flex display="flex" padding="0 0 0 18px">
            <P l_height="18px" f_size="12px" margin="0 10px 0 0 ">
              Weight: 0
            </P>
            <P l_height="18px" f_size="12px" margin="0 10px 0 0 ">
              Quantity: 0
            </P>
          </Flex>
          <Card
            height="378px"
            radius="9px"
            bgc="#fff"
            width="100%"
            min_h="378px"
            padding="18px"
            margin="8px 25px 16px 0 "
            o_y="auto"
          >
            <CardItems
              data={data}
              editable={true}
              // onClick={showConfirm}
              onOk={handleRemove}
              onCancel={() => console.log("Cancel")}
            />
          </Card>
        </Div>
      </Flex>
      <Flex display="flex" j_content="end" align="center" padding="16px 0 0 0 ">
        <Button
          color="#fff"
          bgc="#74BF4F"
          radius="8px"
          padding="5px 15px 5px 15px"
        >
          Close Orders
        </Button>
      </Flex>
      <UserInfo active={active} setActive={setActive} />
      <TableHaypost active={active} setActive={setActive} />
    </ContainerWrapper>
  )
}

export default HayPost
