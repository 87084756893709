import React, {useState, useEffect} from "react"

import {Select, message, Modal, Table, Popconfirm} from "antd"
import {useSelector, useDispatch} from "react-redux"

import styles from "./parcel.module.scss"
import plus from "../../assets/images/plus.svg"
import {
    createParcel,
    getManifest,
    getParcel,
    getManifestByParcelId,
    editParcel,
    setColled,
    getReports,
    filterParcelOrders,
    getCompanies, getOrdersDetails, filterParcels,
} from "../../store/actions"
import {ParcelTableHead} from "../../utils"
import {
    ButtonGroup,
    DataPickerGroup,
    ImageGroup,
    ParcelTable,
    ModalGroup,
    PaginationGroup,
} from "../../components/index"
import AntDataTable from "../../components/AntTable";
import {Eye} from "../../assets/svg-component/Eye";
import edit from "../../assets/images/edit.svg";
import {SortingButtons} from "../../assets/svg-component/SortingButtons";
import {InputSimple} from "../../components/elements";
import {getEvent} from "../../store/actions/EventsAction";
import {changeParcelEvent, fetchSupportDetails} from "../../store/actions/SupportAction";
import {debounceHook} from "../../hooks/useDebounce";
import styled from "styled-components";

const {Option} = Select
const debouncedFilter = debounceHook((fn) => fn(), 500);
const StyledManifestName = styled.div`
  a {
    display: -webkit-box;
    overflow: hidden;
    max-height: 44px;
    text-overflow: ellipsis;
    transition: all 0.2s;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`
const Parcel = () => {
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(15)
    const [active, setActive] = useState(false)
    const [filterData, setFilterData] = useState({})
    const [oneParcelData, setOneParcelData] = useState({})
    const [reportData, setReportData] = useState({
        parcel_id: null,
        manifest: [],
    })

    const isActive = useSelector((state) => state.ordersReducer.bool)
    const parcels = useSelector((state) => state.parcelReducer.parcel)
    const error = useSelector((state) => state.parcelReducer.updateError)
    const parcelsAll = useSelector((state) => state.parcelReducer.allParcel)
    const success = useSelector((state) => state.parcelReducer.updateSuccess)
    const exportErr = useSelector((state) => state.parcelReducer.exportError)
    const manifests = useSelector(
        (state) => state.ManifestReducer.oneParcelManifest
    )
    const [openManifestModal, setOpenManifestModal] = useState(false);
    const [isActiveOptionDetails, setIsActiveOptionDetails] = useState(false);
    const [sorter, setSorter] = useState("");
    const [filterValue, setFilterValue] = useState("");
    const parcelTotal = parcelsAll?.meta?.total
    const [filterFields, setFilterFields] = useState({});

    const filteredManifests = manifests.map((item) => {
        return (
            <Option key={item.id} value={item.id}>
                {item.name}
            </Option>
        )
    })
    const dispatch = useDispatch()

    // useEffect(() => {
    //     dispatch(getParcel(perPage, page))
    // }, [dispatch, error, success, isActive, perPage, page])
    //
    useEffect(() => {
        dispatch(getParcel(1000, 1));

        dispatch(getManifestByParcelId(reportData.parcel_id))
    }, [reportData])

    const handleOpenModal = () => {
        setActive(!active)
        dispatch(getManifest())
    }

    const handleCnacel = () => {
        setActive(false)
        setOneParcelData({})
    }

    const handleChangeManifest = (value) => {
        setReportData({...reportData, manifest: [...value]})
    }

    const onChangeParcel = (value, e) => {
        console.log(value)
        filterFields['parcel_id'] = value;
        setReportData({...reportData, parcel_id: value});
        // setReportData({...reportData, parcel_id: e.key});
        // filterFields['parcels'] = value;
        dispatch(filterParcels(filterFields));
    }

    const setReports = () => {
        console.log(reportData.parcel_id[0]);
        dispatch(getReports(reportData))
    }

    const changePerPage = (value) => {
        setPage(value)
        dispatch(getParcel(perPage, value))
    }

    const addParcer = (data) => {
        dispatch(createParcel(data, parcels))
        setOneParcelData({})
    }

    const updateParcel = (data) => {
        const manifestIds = data.manifest.map((item) => {
            return item.id;
        });

        data.manifest = manifestIds;
        console.log(data);
        dispatch(editParcel(oneParcelData.id, data, parcels))
    }

    // const handleClickEdit = (id) => {
    //     const foundItem = parcels?.find((item) => item.id == id)
    //     setOneParcelData(foundItem)
    //     setActive(!active)
    //     dispatch(getManifest())
    // }
    //
    // const manifestsByParcelId = (id) => {
    //     dispatch(getManifestByParcelId(id))
    //     dispatch(setColled(true))
    // }

    const changeFilterDate = (date, dateString) => {
        setFilterData({start_at: dateString[0], end_at: dateString[1]});

        filterFields['start_at'] = dateString[0];
        filterFields['end_at'] = dateString[1];

        dispatch(filterParcels(filterFields));
        console.log('ok')
    }

    // useEffect(() => {
    //     if (Object.entries(filterData).length > 0) {
    //         dispatch(filterParcelOrders(perPage, page, filterData))
    //     }
    // }, [filterData])

    const percelOption = parcels.map((item) => {
        return (
            <Option key={item.id} value={item.id}>
                {item.parcel_name}
            </Option>
        )
    });
    const handleShowDetails = (id) => {
        dispatch(getManifestByParcelId(id))
        dispatch(setColled(true))
        setOpenManifestModal(true);
    };


    const handleChangeFilterField = (e) => {
        setFilterValue(e.target.value);
        if (e.target.value === "") {
            handleSearchOrder();
        }
    };

    const enterSearchInput = (e, field, type = "input") => {
        // // setFilterFields({...filterFields, filterFields[field] = });
        debouncedFilter(() => {
            if (e.target.value !== "") {
                filterFields[field] = e.target.value;
            } else {
                delete filterFields[field];
            }
            handleSearchOrder();
        });

        if (type === "select") {
            if (e !== "") {
                filterFields[field] = e;
            } else {
                delete filterFields[field];
            }
            handleSearchOrder();
        }
    };

    const handleSearchOrder = () => {
        if (Object.keys(filterFields).length === 0) {
            dispatch(filterParcels(perPage, page))
            // dispatch(fetchSupportDetails(perPage, page));
        } else {
            dispatch(filterParcels(filterFields));
            // dispatch(fetchSupportDetails(filterFields));
            setPerPage(15);
            setPage(1);
        }
    };

    const handleEditParcel = (id) => {
        console.log(id);
        const foundItem = parcels?.find((item) => item.id == id)
        setOneParcelData(foundItem)
        setActive(!active)
        dispatch(getManifest())

    }


    const filteredData = [...parcels].map(
        (
            {
                id,
                parcel_name,
                air_way_bill,
                in_my_way_date,
                freight_forwarder,
                estimated_delivery_date,
                total_weight,
                v_weight,
                manifests,
                current_event,
                destination_status,
                destination_type
            },
        ) => {
            return {
                id,
                parcel_name,
                air_way_bill,
                in_my_way_date,
                freight_forwarder: manifests[0]?.name,
                estimated_delivery_date,
                total_weight,
                v_weight,
                destination_status,
                destination_type
                // manifest: manifests[0]?.name
            };
        }
    );


    let dataSource = filteredData;

    if (sorter) {
        dataSource = [...filteredData].sort((a, b) => {
            if (a.id > b.id) {
                return sorter === "+" ? 1 : -1;
            }
            if (a.id < b.id) {
                return sorter === "+" ? -1 : 1;
            }
            return 0;
        });
    }

    const confirmChangeEvent = (id) => {
      dispatch(changeParcelEvent({parcel_id: id, event_code: 3})).then(() => message.success("Event changed!"))
    }

    const columns = [
        {
            title: "",
            dataIndex: "icon",
            key: "icon",
            render: (_, record) => (
                <div style={{display: "flex", alignItems: "center"}}>
                    <Eye key={record.id} onClick={() => handleShowDetails(record.id)}/>
                    <div><ImageGroup onClick={() => handleEditParcel(record.id)} src={edit}  className={styles.edit_btn} /></div>
                </div>
            ),
            width: 100,
            fixed: "left",
        },
        {
            dataIndex: "id",
            title: () => (
                <div className={""}>
                      <span className={"title_text"}>
                        ID{" "}
                          <SortingButtons
                              color="#757272"
                              handleChange={(direction) => {
                                  setSorter((sorter) => (sorter === direction ? "" : direction));
                              }}
                          />
                      </span>
                </div>
            ),
        },
        {
            dataIndex: "event",
            title: () => (
                <div className={""}>
                      <span className={"title_text"}>
                        Event{" "}
                      </span>
                </div>
            ),
            render: (_, record) => (
                <Popconfirm title={"Are you sure?"} onConfirm={() => confirmChangeEvent(record.id)}>
                    <button className={styles.changeEventBtn}>In Customs</button>
                </Popconfirm>
            )
        },
        {
            dataIndex: "parcel_name",
            title: () => (
                <div className={""}>
                      <span className={"title_text"}>
                        Parcel Name{" "}
                          <SortingButtons
                              color="#757272"
                              handleChange={(direction) => {
                                  setSorter((sorter) => (sorter === direction ? "" : direction));
                              }}
                          />
                      </span>
                </div>
            ),
        },

        {
            dataIndex: "air_way_bill",
            title: () => (
                <div className={""}>
                      <span className={"title_text"}>
                        Air Way Bill{" "}
                          <SortingButtons
                              color="#757272"
                              handleChange={(direction) => {
                                  setSorter((sorter) => (sorter === direction ? "" : direction));
                              }}
                          />
                      </span>
                </div>
            ),
        },
        {
            dataIndex: "in_my_way_date",
            title: () => (
                <div className={""}>
                      <span className={"title_text"}>
                        In My Way Date{" "}
                          <SortingButtons
                              color="#757272"
                              handleChange={(direction) => {
                                  setSorter((sorter) => (sorter === direction ? "" : direction));
                              }}
                          />
                      </span>
                </div>
            ),
        },
        {
            dataIndex: "freight_forwarder",
            title: () => (
                <div className={""}>
                      <span className={"title_text"}>
                        Manifest Name{" "}
                          <SortingButtons
                              color="#757272"
                              handleChange={(direction) => {
                                  setSorter((sorter) => (sorter === direction ? "" : direction));
                              }}
                          />
                      </span>
                </div>
            ),
            render: (_, record) => (
                <StyledManifestName><a download={true} href={`https://api.onex.am/storage/excels/${record.freight_forwarder}`}>{record.freight_forwarder}</a></StyledManifestName>
            )
        },
        {
            dataIndex: "estimated_delivery_date",
            title: () => (
                <div className={""}>
                      <span className={"title_text"}>
                        Estimated delivery date{" "}
                          <SortingButtons
                              color="#757272"
                              handleChange={(direction) => {
                                  setSorter((sorter) => (sorter === direction ? "" : direction));
                              }}
                          />
                      </span>
                </div>
            ),
        },
        {
            dataIndex: "total_weight",
            title: () => (
                <div className={""}>
                      <span className={"title_text"}>
                        Total Weight{" "}
                          <SortingButtons
                              color="#757272"
                              handleChange={(direction) => {
                                  setSorter((sorter) => (sorter === direction ? "" : direction));
                              }}
                          />
                      </span>
                </div>
            ),
        },
        {
            dataIndex: "v_weight",
            title: () => (
                <div className={""}>
                      <span className={"title_text"}>
                        V Weight{" "}
                          <SortingButtons
                              color="#757272"
                              handleChange={(direction) => {
                                  setSorter((sorter) => (sorter === direction ? "" : direction));
                              }}
                          />
                      </span>
                </div>
            ),
        },
    ]


    const manifestModalDataSource = manifests;
    const manifestModalColumns = [
        {
            title: "id",
            dataIndex: "id",
            key: "id"
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Path",
            dataIndex: "path",
            key: "path"
        },
    ]

    return (
        <div className={`container ${
            isActive
                ? `main_parcel_mobile_active ${styles.main_parcel__wrapper_active}`
                : `main_parcel_mobile ${styles.main_parcel__wrapper}`
        }
          `}>
            {active && (
                <ModalGroup
                    active={active}
                    setActive={setActive}
                    editedItem={oneParcelData}
                    handleCnacel={handleCnacel}
                    createParcel={
                        Object.entries(oneParcelData).length > 0 ? updateParcel : addParcer
                    }
                />
            )}

            <section className={styles.main_parcel}>
                <div

                >
                    {exportErr && message.error(exportErr)}
                    <br/>
                    <div className={` ${styles.main_parcel__wrapper_head}`}>
                        <div className={styles.main_parcel__wrapper_head_items}>
                            <ButtonGroup
                                onClick={handleOpenModal}
                                className={styles.main_parcel__wrapper_head_button}
                            >
                                <ImageGroup
                                    src={plus}
                                    className={styles.main_parcel__wrapper_head_button_plus}
                                />
                                <span>Create Parcel</span>
                            </ButtonGroup>
                            <div style={{paddingLeft: "53px", display: "flex"}}>
                                <div className={styles.main_parcel__wrapper_selectName}>
                                    <Select
                                        showSearch
                                        // className={styles.main_parcel__wrapper_select}
                                        className={
                                            exportErr !== ""
                                                ? styles.main_parcel__wrapper_select_error
                                                : styles.main_parcel__wrapper_select
                                        }
                                        placeholder="Parcel Name"
                                        datas={parcels}
                                        onChange={onChangeParcel}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {percelOption}
                                    </Select>
                                </div>
                                <div className={styles.main_parcel__wrapper_selectName}>
                                    <Select
                                        showSearch
                                        value={reportData.manifest}
                                        onChange={handleChangeManifest}
                                        className={styles.main_parcel__wrapper_select}
                                        mode="multiple"
                                        placeholder={"Select Manifest"}
                                    >
                                        {filteredManifests}
                                    </Select>
                                </div>

                                <ButtonGroup
                                    onClick={setReports}
                                    className={styles.main_parcel__wrapper_export}
                                >
                                    Export excel
                                </ButtonGroup>
                            </div>
                            <div className={styles.main_parcel__wrapper_date}>
                                <DataPickerGroup onChange={changeFilterDate}/>
                            </div>
                        </div>
                    </div>
                    <Modal
                        title="Manifests"
                        centered
                        open={openManifestModal}
                        onOk={() => setOpenManifestModal(false)}
                        onCancel={() => setOpenManifestModal(false)}
                        width={1300}
                        footer={""}
                        pagination={false}
                    >
                        <Table width={"100%"} columns={manifestModalColumns} dataSource={manifestModalDataSource}/>
                    </Modal>
                    <AntDataTable
                        columns={columns}
                        dataSource={dataSource}
                        // loading={load}
                        perPage={perPage}
                        page={page}
                        changePerPage={changePerPage}
                        total={parcelsAll}
                        wrapper={false}
                        scrollX={1500}
                        pagination={true}
                    >

                    </AntDataTable>
                </div>
            </section>
        </div>
    )
}

export default Parcel
