import React from "react";

import {
  Select,
  message,
  Modal,
  Button,
  Input,
  Tooltip,
  Popconfirm,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import edit from "../../assets/images/edit.svg";
import ImageGroup from "../../components/ImageGroup/index";
import { SelectForTable } from "../../components/elements/SelectForTable";
import { Eye } from "../../assets/svg-component/Eye";
import AntDataTable from "../../components/AntTable";
import { InputSimple } from "../../components/elements";
import { getCompanies, searchOrder } from "./../../store/actions/OrdersAction";
import { SortingButtons } from "../../assets/svg-component/SortingButtons";
import OptionDetails from "../../components/OptionDetails";
import { getOrders, getParcel } from "./../../store/actions";
import debounce from "lodash.debounce";
import { getOrdersDetails, setNewEvent } from "../../store/actions";
import { getEvent } from "./../../store/actions/EventsAction";
import { debounceHook } from "./../../hooks/useDebounce";
import { fetchSupportDetails } from "../../store/actions/SupportAction";
import InputSelect from "../../components/elements/InputSelect";
import styles from "../Orders/orders.module.scss";
import { ButtonGroup } from "../../components";
import { DataPickerGroup } from "../../components";
import { distributeOrders } from "./../../store/actions/OrdersAction";
import stylesWrapper from "../../containers/Branches/branches.module.scss";
import styled from "styled-components";
const { Option } = Select;

const debouncedFilter = debounceHook((fn) => fn(), 500);

const StyledSelect = styled.div`
  margin-right: 50px;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
    border: ${({ hover }) => hover && "none"};
    background: ${({ bg }) => bg || "#ffffff"};
    color: ${({ color }) => color || "#262626"};
    font-family: "Montserrat";

    &:focus,
    &:hover,
    &:active {
      border-color: #5dba2f;
      box-shadow: #5dba2f;
      border-right-width: 1px;
      outline: 0;
    }
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: #5dba2f;
    box-shadow: 0 0 0 2px rgb(93 186 47 / 20%);
    border-right-width: 1px;
    outline: 0;
  }
`;
const Lockers = () => {
  const [perPage, setPerPage] = useState(100);
  const [page, setPage] = useState(1);
  const [filterValue, setFilterValue] = useState("");
  const [filterFields, setFilterFields] = useState({});
  const [isActiveOptionDetails, setIsActiveOptionDetails] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState();
  const [isEventChangePopup, setIsEventChangePopup] = useState(false);
  const [sorter, setSorter] = useState("");
  const { data = [], load, allData } = useSelector((state) => state.support);
  const order = useSelector((state) => state.ordersReducer.oneOrder);
  const events = useSelector((state) => state.EventReducer.event);
  const [eventCode, setEventCode] = useState();
  const [loading, setLoading] = useState(false);
  const [parcelId, setParcelId] = useState("");
  const [inputValue, setInputValue] = useState({});
  const [companyValue, setCompanyValue] = useState({});
  const company = useSelector((state) => state.ordersReducer.company);
  const [filterBy, setFilterBy] = useState("shipped_date");
  const [filterData, setFilterData] = useState({});
  const isActive = useSelector((state) => state.ordersReducer.bool);

  const parcelsList = useSelector((state) => state.parcelReducer.parcel);
  let selectData = [...parcelsList].map((name) => ({
    ket: name.id,
    title: name.parcel_name,
    value: name.id,
  }));

  useEffect(() => {
    setLoading(true);
    dispatch(
      fetchSupportDetails({
        per_page: perPage,
        page: page,
        delivery_type: "pudo",
        destination_status: "110",
        status: "scanned",
      })
    );
    dispatch(getParcel(1000, 1));
    dispatch(getCompanies());
    dispatch(getEvent());
  }, []);

  const distributeError = useSelector(
    (state) => state.ordersReducer.errorDistribute
  );
  const distributeSuccess = useSelector(
    (state) => state.ordersReducer.successDistribute
  );
  const wrapperRef = useRef();
  const isLoggedIn = localStorage.getItem("accessToken");
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn == null) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate, user]);

  const handleShowDetails = (id) => {
    dispatch(getOrdersDetails(id));
    dispatch(getEvent());
    setIsActiveOptionDetails(true);
  };

  const changePerPage = (item, value) => {
    const newParams = {};
    Object.entries(filterFields).forEach(([key, value]) => {
      if (value) {
        newParams[key] = value;
      }
    });
    setPage(item);
    setPerPage(value);
    let pageList = { page: item, per_page: value, ...newParams };
    dispatch(fetchSupportDetails(pageList));
  };

  const handleEventChangePopup = (id) => {
    dispatch(getEvent());
    setSelectedOrder(id);
    setIsEventChangePopup(true);
  };

  const enterSearchInput = (e, field, type = "input") => {
    // setFilterFields({...filterFields, filterFields[field] = });
    debouncedFilter(() => {
      if (e.target.value !== "") {
        filterFields[field] = e.target.value;
      } else {
        delete filterFields[field];
      }
      handleSearchOrder();
      if (e.key === "Enter") {
        e.target.select();
      }
    });

    if (type === "select") {
      if (e !== "") {
        filterFields[field] = e;
      } else {
        delete filterFields[field];
      }
      if (e === "all") {
        delete filterFields[field];
      }
      handleSearchOrder();
    }
  };

  const handleChangeFilterField = (e) => {
    setFilterValue(e.target.value);
    if (e.target.value === "") {
      handleSearchOrder();
    }
  };

  const handleSearchOrder = () => {
    if (Object.keys(filterFields).length === 0) {
      dispatch(fetchSupportDetails(perPage, page));
    } else {
      filterFields["per_page"] = 100;
      filterFields["page"] = 1;
      dispatch(fetchSupportDetails(filterFields));
      setPerPage(100);
      setPage(1);
    }
  };

  const handleSetEvent = () => {
    if (eventCode) {
      dispatch(
        setNewEvent({ order_id: selectedOrder, event_code: eventCode })
      ).then(() => {
        filterFields["page"] = page;
        filterFields["per_page"] = perPage;
        dispatch(fetchSupportDetails(filterFields));
        message.success("Event changed !");
        setIsEventChangePopup(false);
      });
    }
  };

  const closeEventChangePopup = () => {
    setIsEventChangePopup(false);
  };

  const handleChangeEvent = (value) => {
    setEventCode(value);
  };

  const handleChangeParcelName = (val) => {
    setParcelId(val);
    setPage(1);
    setPerPage(15);
    setInputValue({ parcel_id: val });
    let parcelIds = [];
    // delivery_type: 'pudo', destination_status: "110", status: 'scanned
    filterFields["delivery_type"] = "pudo";
    filterFields["destination_status"] = "110";
    filterFields["status"] = "scanned";
    if (val) {
      filterFields["parcels[]"] = val;
      dispatch(fetchSupportDetails(filterFields));
    } else {
      delete filterFields["parcels[]"];
      dispatch(fetchSupportDetails(filterFields));
    }
  };

  const handleChangecompany = (value, e) => {
    const { name } = e;
    filterFields["company"] = value;
    console.log(filterFields);
    dispatch(fetchSupportDetails(filterFields));
    setCompanyValue(name !== "all" ? { company_id: value } : {});
  };

  const hamdleDistribute = () => {
    dispatch(distributeOrders({ parcel_id: parcelId })).then((res) => {
      console.log(res);
      message.success("Success !");
    });
    dispatch(
      fetchSupportDetails({
        per_page: perPage,
        page: page,
        delivery_type: "pudo",
        destination_status: "110",
        status: "scanned",
      })
    );
  };

  const changeFormat = (value) => {
    setFilterBy(value);
  };

  const changeFilterDate = (date, dateString) => {
    const dateFilter = {
      start_at: dateString[0],
      end_at: dateString[1],
      format: filterBy,
    };

    filterFields["date[]"] = dateFilter;
    // filterFields['end_at'] = dateString[1];
    // filterFields['format'] = filterBy;
    dispatch(fetchSupportDetails(filterFields));

    // setFilterData({start_at: dateString[0], end_at: dateString[1]});
  };

  const eventsArray = events.map((event) => {
    return { title: event.event_description, code: event.event_code };
  });

  const filteredData = [...data].map(
    (
      {
        id,
        parcel,
        tracking_number,
        courier,
        receiver_address_line1,
        receiver_full_name,
        order_number,
        deliveryStatus,
        fullName,
        receiver_phone,
        addressLine1,
        delivery_type,
        pickup_point,
        receiver_city,
        receiver_state,
        receiver_country,
        status,
        parcel_weight,
        domentions,
        shipped_date,
        id_country,
        id_number,
        id_type,
        current_event,
        parcel_height,
        parcel_width,
        parcel_length,
        destination_status,
        destination_type,
        support_comment,
      },
      i
    ) => {
      return {
        key: id,
        id: id,
        percelName: parcel?.parcel_name,
        trackingNo: tracking_number,
        deliveryStatus: "",
        fullName: receiver_full_name,
        phone: receiver_phone,
        addressLine1: receiver_address_line1,
        deliveryType: delivery_type,
        pickupPoint: `${
          pickup_point?.address !== undefined ? pickup_point.address : ""
        } ${
          pickup_point?.description !== undefined
            ? pickup_point?.description
            : ""
        }`,
        city: receiver_city,
        state: receiver_state,
        country: receiver_country,
        status: status,
        comment: support_comment,
        percelWeight: parcel_weight,
        domentions: `H: ${parcel_height}, L: ${parcel_length}, W: ${parcel_width}`,
        shippedDate: shipped_date,
        idCountry: id_country,
        idType: id_type,
        current_event: current_event?.event_description,
        destination_status,
        destination_type,
        id_number,
      };
    }
  );

  let dataSource = filteredData;

  if (sorter) {
    dataSource = [...filteredData].sort((a, b) => {
      if (a.id > b.id) {
        return sorter === "+" ? 1 : -1;
      }
      if (a.id < b.id) {
        return sorter === "+" ? -1 : 1;
      }
      return 0;
    });
  }

  const columns = [
    {
      title: "",
      dataIndex: "icon",
      key: "icon",
      render: (_, record) => (
        <Eye key={record.id} onClick={() => handleShowDetails(record.id)} />
      ),
      width: 32,
      fixed: "left",
    },
    {
      dataIndex: "id",
      render: (_, record) => (
        <div
          style={{
            backgroundColor:
              record.destination_type === "locker" &&
              record.destination_status === "0"
                ? ""
                : record.destination_status === "110" &&
                  record.destination_type === null
                ? "red"
                : record.destination_status === null &&
                  record.destination_type === "branch"
                ? "lightblue"
                : "",
          }}
        >
          {record.id}
        </div>
      ),
      title: () => (
        <div className={""}>
          <span className={"title_text"}>
            ID{" "}
            <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                setSorter((sorter) => (sorter === direction ? "" : direction));
              }}
            />
          </span>

          <InputSimple
            width={"100%"}
            onChange={(e) => handleChangeFilterField(e)}
            onKeyDown={(e) => enterSearchInput(e, "id")}
          />
        </div>
      ),
    },
    {
      dataIndex: "percelName",
      title: () => (
        <div>
          <span className="title_text">
            Percel Name{" "}
            <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                setSorter((sorter) => (sorter === direction ? "" : direction));
              }}
            />
          </span>
          <InputSimple
            width={"100%"}
            onChange={(e) => handleChangeFilterField(e)}
            onKeyDown={(e) => enterSearchInput(e, "parcel_name")}
          />
        </div>
      ),
    },
    {
      dataIndex: "trackingNo",
      // sorter: (a, b) => a.trackingNo < b.trackingNo,
      title: () => (
        <div>
          <span className={"title_text"}>
            Tracking No{" "}
            <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                setSorter((sorter) => (sorter === direction ? "" : direction));
              }}
            />
          </span>
          <InputSimple
            width={"100%"}
            onChange={(e) => handleChangeFilterField(e)}
            onKeyDown={(e) => enterSearchInput(e, "tracking_number")}
          />
        </div>
      ),
    },
    {
      dataIndex: "deliveryStatus",
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          <Tooltip title={record.current_event}>
            <Input
              value={record.current_event}
              placeholder="Event"
              style={{ fontSize: "10px", width: "75%", borderRadius: "6px" }}
            />
          </Tooltip>
          <button
            onClick={() => handleEventChangePopup(record.id)}
            style={{ backgroundColor: "transparent" }}
          >
            <ImageGroup src={edit} />
          </button>
        </div>
      ),
      title: () => (
        <div>
          <span className="title_text">Delivery Status</span>
          <SelectForTable
            options={eventsArray}
            enterSearchInput={enterSearchInput}
            fieldName={"delivery_status"}
            keyValue={true}
          />
        </div>
      ),
    },
    {
      dataIndex: "fullName",
      title: () => (
        <div>
          <span className="title_text">
            Full Name{" "}
            <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                setSorter((sorter) => (sorter === direction ? "" : direction));
              }}
            />
          </span>
          <InputSimple
            width={"100%"}
            onChange={(e) => handleChangeFilterField(e)}
            onKeyDown={(e) => enterSearchInput(e, "receiver_full_name")}
          />
        </div>
      ),
    },
    {
      dataIndex: "phone",
      title: () => (
        <div>
          <span className="title_text">
            Phone{" "}
            <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                setSorter((sorter) => (sorter === direction ? "" : direction));
              }}
            />
          </span>
          <InputSimple
            width={"100%"}
            onChange={(e) => handleChangeFilterField(e)}
            onKeyDown={(e) => enterSearchInput(e, "receiver_phone")}
          />
        </div>
      ),
    },
    {
      dataIndex: "addressLine1",
      title: () => (
        <div>
          <span className="title_text">
            Address line 1{" "}
            <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                setSorter((sorter) => (sorter === direction ? "" : direction));
              }}
            />
          </span>
          <InputSimple
            width={"100%"}
            onChange={(e) => handleChangeFilterField(e)}
            onKeyDown={(e) => enterSearchInput(e, "receiver_address_line1")}
          />
        </div>
      ),
    },
    {
      dataIndex: "deliveryType",
      title: () => (
        <div>
          <span className="title_text">
            Delivery Type{" "}
            <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                setSorter((sorter) => (sorter === direction ? "" : direction));
              }}
            />
          </span>

          <SelectForTable
            options={["hayPost", "homeDelivery", "branch", "locker"]}
            enterSearchInput={enterSearchInput}
            fieldName={"delivery_type"}
          />
        </div>
      ),
    },
    {
      dataIndex: "pickupPoint",
      title: () => (
        <div>
          <span className="title_text">
            Pickup Point
            <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                setSorter((sorter) => (sorter === direction ? "" : direction));
              }}
            />
          </span>
          <InputSimple
            width={"100%"}
            onChange={(e) => handleChangeFilterField(e)}
            onKeyDown={(e) => enterSearchInput(e, "pudo_reference")}
          />
        </div>
      ),
      render: (_, record) => (
        <Tooltip title={record.pickupPoint}>
          <div className={"pickup_point"}>{record.pickupPoint}</div>
        </Tooltip>
      ),
    },
    {
      dataIndex: "city",
      title: () => (
        <div>
          <span className="title_text">
            City{" "}
            <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                setSorter((sorter) => (sorter === direction ? "" : direction));
              }}
            />
          </span>
          <InputSimple
            width={"100%"}
            onChange={(e) => handleChangeFilterField(e)}
            onKeyDown={(e) => enterSearchInput(e, "receiver_city")}
          />
        </div>
      ),
    },
    {
      dataIndex: "state",
      title: () => (
        <div>
          <span className="title_text">
            State{" "}
            <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                setSorter((sorter) => (sorter === direction ? "" : direction));
              }}
            />
          </span>
          <InputSimple
            width={"100%"}
            onChange={(e) => handleChangeFilterField(e)}
            onKeyDown={(e) => enterSearchInput(e, "receiver_state")}
          />
        </div>
      ),
    },
    {
      dataIndex: "country",
      title: () => (
        <div>
          <span className="title_text">
            Country{" "}
            <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                setSorter((sorter) => (sorter === direction ? "" : direction));
              }}
            />
          </span>
          <InputSimple
            width={"100%"}
            onChange={(e) => handleChangeFilterField(e)}
            onKeyDown={(e) => enterSearchInput(e, "receiver_country")}
          />
        </div>
      ),
    },
    {
      dataIndex: "status",
      title: () => (
        <div>
          <span className="title_text">
            Status{" "}
            <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                setSorter((sorter) => (sorter === direction ? "" : direction));
              }}
            />
          </span>
          <InputSimple
            width={"100%"}
            onChange={(e) => handleChangeFilterField(e)}
            onKeyDown={(e) => enterSearchInput(e, "status")}
          />
        </div>
      ),
    },
    {
      dataIndex: "comment",
      title: () => (
        <div>
          <span className="title_text">
            Comment{" "}
            <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                setSorter((sorter) => (sorter === direction ? "" : direction));
              }}
            />
          </span>
          <InputSimple
            width={"100%"}
            onChange={(e) => handleChangeFilterField(e)}
            onKeyDown={(e) => enterSearchInput(e, "support_comment")}
          />
        </div>
      ),
    },
    {
      dataIndex: "percelWeight",
      title: () => (
        <div>
          <span className="title_text">
            Percel Weight{" "}
            <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                setSorter((sorter) => (sorter === direction ? "" : direction));
              }}
            />
          </span>
          <InputSimple
            width={"100%"}
            onChange={(e) => handleChangeFilterField(e)}
            onKeyDown={(e) => enterSearchInput(e, "parcel_weight")}
          />
        </div>
      ),
    },
    {
      dataIndex: "domentions",
      title: () => (
        <div>
          <span className="title_text">
            Domentions{" "}
            <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                setSorter((sorter) => (sorter === direction ? "" : direction));
              }}
            />
          </span>
          <InputSimple
            width={"100%"}
            onChange={(e) => handleChangeFilterField(e)}
            onKeyDown={(e) => enterSearchInput(e, "domentions")}
          />
        </div>
      ),
    },
    {
      dataIndex: "shippedDate",
      title: () => (
        <div>
          <span className="title_text">
            Shipped Date{" "}
            <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                setSorter((sorter) => (sorter === direction ? "" : direction));
              }}
            />
          </span>
          <InputSimple
            width={"100%"}
            onChange={(e) => handleChangeFilterField(e)}
            onKeyDown={(e) => enterSearchInput(e, "shipped_date")}
          />
        </div>
      ),
    },
    {
      dataIndex: "idCountry",
      title: () => (
        <div>
          <span className="title_text">
            ID Country{" "}
            <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                setSorter((sorter) => (sorter === direction ? "" : direction));
              }}
            />
          </span>
          <InputSimple
            width={"100%"}
            onChange={(e) => handleChangeFilterField(e)}
            onKeyDown={(e) => enterSearchInput(e, "id_country")}
          />
        </div>
      ),
    },
    {
      dataIndex: "id_number",
      title: () => (
        <div>
          <span className="title_text">
            ID Number{" "}
            <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                setSorter((sorter) => (sorter === direction ? "" : direction));
              }}
            />
          </span>
          <InputSimple
            width={"100%"}
            onChange={(e) => handleChangeFilterField(e)}
            onKeyDown={(e) => enterSearchInput(e, "id_number")}
          />
        </div>
      ),
    },
    {
      dataIndex: "idType",
      title: () => (
        <div>
          <span className="title_text">
            ID Type{" "}
            <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                setSorter((sorter) => (sorter === direction ? "" : direction));
              }}
            />
          </span>
          <InputSimple
            width={"100%"}
            onChange={(e) => handleChangeFilterField(e)}
            onKeyDown={(e) => enterSearchInput(e, "id_type")}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "10px 0",
        }}
      >
        <StyledSelect>
          <Select
            showSearch
            mode={"multiple"}
            style={{ width: 200, marginRight: 30 }}
            placeholder={"Parcel Name"}
            onChange={(val) => handleChangeParcelName(val)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {selectData.map((item, key) => {
              return <Option value={item.ket}>{item.title}</Option>;
            })}
          </Select>
        </StyledSelect>
        <div className={styles.main_orders__items_sel}>
          <Select defaultValue={"All"} onChange={handleChangecompany}>
            <Option value="" name={"all"}>
              All
            </Option>
            {company.map(({ id, name }) => {
              return (
                <Option key={id} value={id} name={name}>
                  {name}
                </Option>
              );
            })}
          </Select>
        </div>
        <Popconfirm title="Are you sure?" onConfirm={hamdleDistribute}>
          <ButtonGroup
            className={styles.main_orders_item_distribute}
            disabled={parcelId === ""}
          >
            Distribute
          </ButtonGroup>
        </Popconfirm>
      </div>

      <Modal
        title="Select Event"
        open={isEventChangePopup}
        footer={""}
        onCancel={() => closeEventChangePopup(false)}
      >
        <>
          <Select
            style={{ width: "100%" }}
            defaultValue={"Event Code & Name"}
            onChange={handleChangeEvent}
          >
            {events.length
              ? events.map((event, key) => (
                  <Option value={event.event_code} key={key}>
                    {event.event_code} - {event.event_description}
                  </Option>
                ))
              : null}
          </Select>
          <Button
            type="primary"
            className="set-event-btn"
            onClick={() => handleSetEvent()}
            style={{
              background: "#5dba2f",
              borderColor: "#5dba2f",
              borderRadius: "4px",
              marginTop: "15px",
            }}
          >
            Set Event
          </Button>
        </>
      </Modal>
      <OptionDetails
        isopenModal={isActiveOptionDetails}
        setIsOpenModal={setIsActiveOptionDetails}
        item={order}
      />
      <div
        className={`container
       ${
         isActive
           ? `main_delivry_mobile_active ${styles.main_delivery__wrapper_active}`
           : `main_delivry_mobile ${styles.main_delivery__wrapper}`
       }    
     `}
      >
        <AntDataTable
          columns={columns}
          dataSource={dataSource}
          loading={load}
          perPage={perPage}
          page={page}
          changePerPage={changePerPage}
          total={allData}
          wrapper={false}
          pagination={true}
        />
      </div>
    </>
  );
};

export default Lockers;
