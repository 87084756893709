import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import styles from "./header.module.scss";

import { capitalize } from "../../utils";
import logOut from "../../assets/images/logOut.svg";
import ImageGroup from "../../components/ImageGroup";
import arrowDown from "../../assets/images/arrow_select.svg";
import notification from "../../assets/images/notification.svg";

const Header = () => {
  const [active, setActive] = useState(false);

  const isActive = useSelector((state) => state.ordersReducer.bool);

  const user = JSON.parse(localStorage.getItem("userInfo"));

  const firstLetter = user?.first_name
    .split("")
    .splice(0, 1)
    .join()
    .toUpperCase();

  const location = useLocation();
  const navigate = useNavigate();

  const pathName = location.pathname
    .split("")
    .splice(1, location.pathname.length)
    .join("");

  const handleLogOut = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userInfo");
    setActive(!active);
    navigate("/login");
  };

  return (
    <section className={`  ${styles.main_header}`}>
      <div
        className={
          isActive
            ? styles.main_header__wrapper_active
            : styles.main_header__wrapper
        }
      >
        <p className={styles.main_header__wrapper_title}>
          {pathName == "" ? "Orders" : capitalize(pathName)}
        </p>

        <div style={{ display: "flex", alignItems: "center" }}>
          {firstLetter && <ImageGroup src={notification} />}

          <div className={styles.main_header__wrapper_info}>
            {firstLetter && (
              <div
                onClick={() => {
                  setActive(!active);
                }}
                className={styles.main_header__wrapper_info_select}
              >
                <div className={styles.main_header__wrapper_info_select_logo}>
                  {firstLetter}
                </div>
                <ImageGroup src={arrowDown} />
              </div>
            )}
            <div
              className={
                !active
                  ? styles.main_header__wrapper_info_option
                  : styles.main_header__wrapper_info_option_active
              }
            >
              <p
                className={styles.main_header__wrapper_info_option_active_item}
              >
                {user?.email}
              </p>

              <div
                className={styles.main_header__wrapper_info_option_active_line}
              ></div>
              <div
                className={styles.main_header__wrapper_info_option_active_wrap}
              >
                <ImageGroup src={logOut} />
                <p
                  onClick={handleLogOut}
                  className={
                    styles.main_header__wrapper_info_option_active_wrap_item
                  }
                >
                  Log Out
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
