import styled from "styled-components"

const Button = styled.button`
  font-style: normal;
  font-family: "Montserrat";
  color: ${({ color }) => color};
  padding: ${({ padding }) => padding};
  background-color: ${({ bgc }) => bgc};
  border-radius: ${({ radius }) => radius};
  font-size: ${({ f_size }) => f_size || "14px"};
  font-weight: ${({ f_weight }) => f_weight || "600"};
  line-height: ${({ l_height }) => l_height || "22px"};
`
export default Button
