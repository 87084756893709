import {Form, Select, Table} from "antd";
import React, { useEffect, useState } from "react";
import "./ant-table.scss";
import { useDispatch, useSelector } from "react-redux";
import { EditableCell } from "./EditableCell";
import { columns } from "./ant-table-columns";
import moment from "moment";
import { fetchSupportDetails ,supportDetailsUpdate} from "../../store/actions/SupportAction";
import useWindowSize from "../../hooks/useWindowSize.hooks";
import styles from "../../containers/Support/support.module.scss";
import InputSelect from "../elements/InputSelect";
import {DataPickerGroup} from "../index";
import styled from "styled-components";

const StyledSelect = styled.div `
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
    border: ${({hover}) => hover && "none"};
    background: ${({bg}) => bg || "#ffffff"};
    color: ${({color}) => color || "#262626"};
    font-family: 'Montserrat';

    &:focus,
    &:hover,
    &:active {
      border-color: #5dba2f;
      box-shadow: #5dba2f;
      border-right-width: 1px;
      outline: 0;
    }
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
    border-color: #5dba2f;
    box-shadow: 0 0 0 2px rgb(93 186 47 / 20%);
    border-right-width: 1px;
    outline: 0;
  }
`

const AntTable = ({filterParcelName, setPage, setPerPage, margin = "0" ,handleShow=()=>{}}) => {
  const dispatch = useDispatch();
  const {data=[], allData = {}, load } = useSelector((state) => state.support);
  const [form] = Form.useForm();
  const {height}=useWindowSize()
  const [sorter, setSorter] = useState("");
  const [editingKey, setEditingKey] = useState("");
  const parcelsList = useSelector((state) => state.parcelReducer.parcel);
  const {Option} = Select

  const [filterField, setFilterField] = useState({
    id: null,
    delivery_type: null,
    receiver_full_name: null,
    receiver_phone: null,
    receiver_address_line1: null,
    receiver_city: null,
    pickup_point: null,
    receiver_state: null,
    tracking_number: null,
    status: null,
    current_event: null,
    parcel_weight: null,
    parcel_height: null,
    parcel_length: null,
    parcel_width: null,
    shipped_date: null,
    parcel: null,
    receiver_zip:null,
    parcel_name: null
  });
  useEffect(() => {
    const newParams = {};
    Object.entries(filterField).forEach(([key, value]) => {
      if (value) {
        newParams[key] = value;
      }
      // if(filterParcelName) {
      //   newParams['parcel_id'] = filterParcelName;
      // }
    });

    console.log(newParams);
    const handler = setTimeout(() => {
      dispatch(fetchSupportDetails(newParams));
    }, 1000);

    return () => clearTimeout(handler);
  }, [filterField]);
  
  const filterData = [...data].map(
    (
      {
        id,
        delivery_type,
        receiver_full_name,
        receiver_phone,
        receiver_address_line1,
        receiver_city = "",
        pickup_point,
        receiver_state = "",
        tracking_number,
        status,
        current_event = "",
        parcel_weight,
        parcel_height,
        parcel_length,
        parcel_width,
        shipped_date,
        parcel,
        id_number,
        support_comment,
        receiver_zip
      },
      i
    ) => {
      return {
        key: id,
        ID: id,
        full_name: receiver_full_name,
        receiver_phone: receiver_phone,
        receiver_address_line1: receiver_address_line1,
        id_number:id_number,
        operation: "operation",
        delivery_type: delivery_type,
        pick_up_point: `${pickup_point?.description ? pickup_point?.description : ""}\n ${pickup_point?.name ? pickup_point?.name : ""}`,
        city: receiver_city,
        state: receiver_state,
        tracking_no: tracking_number,
        delivery_status: current_event?.event_description,
        status: status,
        receiver_zip:receiver_zip,
        support_comment:support_comment,
        parcel_weight: parcel_weight,
        dimensions: `${parcel_height} x  ${parcel_length} x ${parcel_width}`,
        shipped_date: moment(shipped_date).format("YYYY-MM-DD"),
        parcel_name: parcel?.parcel_name,
      };

    }
  );
  let dataSource = filterData;
  if (sorter) {
    dataSource = [...filterData].sort((a, b) => {
      if (a.id_number > b.id_number) {
        return sorter === "+" ? 1 : -1;
      }
      if (a.id_number < b.id_number) {
        return sorter === "+" ? -1 : 1;
      }
      return 0;
    });
  }
  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      "receiver_address_line1": record.receiver_address_line1,
        "receiver_phone": record.receiver_phone,
      "id_number":record.id_number
    });

    setEditingKey(record.key);
  };


  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      dispatch(supportDetailsUpdate(key,row)).then(res =>{
        if(res) {
          setEditingKey("");
        }
      })
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };
  const handleUpdateDeliveryType = (key,value)=>{
    dispatch(supportDetailsUpdate(key,value))
  }
  const { column } = columns(
    isEditing,
    editingKey,
    cancel,
    edit,
    save,
    filterField,
    setFilterField,
      handleUpdateDeliveryType,
      handleShow,
      sorter,
      setSorter
  );
  const mergedColumns = column.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "phone" ? "tel" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  let selectData = [...parcelsList].map(name =>({
    ket: name.id,
    title: name.parcel_name,
    value:name.id

  }))

  const onChangeDate = (_, dateString) => {

    let data = { date:[{start_at: dateString[0], end_at: dateString[1]}] }
    if(dateString[0].length > 0) {
      dispatch(fetchSupportDetails(data))
    }else {
      dispatch(fetchSupportDetails({}))
    }
  };

  const handleChangeParcelName= (val)=>{
    // setPage(1)
    // setPerPage(15)
    setFilterField({ ...filterField, parcels:val })
    // if(val){
    //   dispatch(fetchSupportDetails({parcel_id:val}))
    // }else {
    //   dispatch(fetchSupportDetails({}))
    // }

  }
  return (
    <div style={{ margin: margin }}>
      <div
          className={styles.main_branches__wrapper_date}
          style={{ justifyContent: "space-between", margin: "30px 0" }}
      >
        {/*<InputSelect width={"250px"} margin={"0 20px 0 0"} data={selectData} value={"Parcel Name"} obj={true} onChange={(val)=>handleChangeParcelName(val)} load={load}/>*/}
        <StyledSelect>
        <Select mode={"multiple"} style={{width: 200, marginRight: 30, borderRadius: "0.5rem"}} placeholder={"Parcel Name"}filterOption={(input, option) =>
                           option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                       }  onChange={(val) => handleChangeParcelName(val)}>
          {
            selectData.map((item, key) => {
              return <Option value={item.ket}>{item.title}</Option>
            })
          }
        </Select>
        </StyledSelect>
        <DataPickerGroup onChange={onChangeDate} />
      </div>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={dataSource}
          columns={mergedColumns}
          scroll={{
            x: 1500,
            y: height - 390,
          }}
          className={"table"}
          loading={load}
          pagination={false}
          tableLayout="fixed"
        />
      </Form>
    </div>
  );
};
export default AntTable;
