import styled from "styled-components";
import {Select} from "antd";

const {Option} = Select;

const StyledSelect = styled.div`
  .ant-select {
    width: 100%;

    .ant-select-selector {
      border-radius: 8px;
    }
  }
`;

export const SelectForTable = ({options, enterSearchInput, fieldName, keyValue}) => {

    const handleChange = (value) => {
        enterSearchInput(value, fieldName, "select")
    }

    return (
        <StyledSelect>
            <Select defaultValue={"All"} onChange={handleChange}>
                <Option value="all">All</Option>
                {
                    keyValue ? (
                        options.map((option, key) => {
                            return (
                                <Option key={key} value={option.code}>{option.title}</Option>
                            )
                        })
                    ) : options.map((option, key) => {
                        return (
                            <Option key={key} value={option}>{option}</Option>
                        )
                    })
                }
            </Select>
        </StyledSelect>
    );
};
