import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import styles from "./optionDetails.module.scss";

import ImageGroup from "../ImageGroup";
import back_arrow from "../../assets/images/close_arrow.svg";

const OptionDetails = ({ isopenModal, setIsOpenModal, item }) => {
  const events = useSelector((state) => state.EventReducer.event);
  const order = useSelector((state) => state.ordersReducer.oneOrder);
  const location = useLocation();

  const modalRef = useRef();

  useEffect(() => {
    const checkOutsideClick = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        setIsOpenModal(false);
      }
    };

    document.addEventListener("mousedown", checkOutsideClick);
    return () => {
      document.removeEventListener("mousedown", checkOutsideClick);
    };
  }, [setIsOpenModal]);

  const pathName = location.pathname
    .split("")
    .splice(1, location.pathname.length)
    .join("");
  const handleClose = () => {
    setIsOpenModal(false);
  };

  const eventsCard = order?.history?.map((item) => {
    return (
      <div style={{ display: "flex" }}>
        <div
          key={item.id}
          className={styles.main_option_wrapper_cards__items_item}
        >{item.created_at}</div>
        {/* <span style={{ marginLeft: "15px" }}>{item.event_code}</span>{" "} */}
        <p style={{ marginLeft: "10px" }}>{item.event.event_description}</p>
      </div>
    );
  });

  return (
    <section style={{ display: "flex" }}>
      <div className={isopenModal ? styles.opasity : styles.opasity_show}></div>
      <div
        className={!isopenModal ? styles.main_option : styles.main_option_open}
      >
        <div className={styles.main_option_wrapper}>
          <div className={styles.main_option_wrapper_cards} ref={modalRef}>
            <div className={styles.main_option_wrapper__nav}>
              <ImageGroup
                onClick={handleClose}
                src={back_arrow}
                className={
                  isopenModal
                    ? styles.main_option_wrapper__nav_item
                    : styles.main_option_wrapper__nav_item_close
                }
              />
              <p className={styles.main_option_wrapper__nav_item}>
                {pathName === "" ? `Orders / Details` : `${pathName} / Details`}
              </p>
            </div>
            <div style={{ height: "90vh", overflowY: "auto" }}>
              <div className={styles.main_option_wrapper_cards__items}>
                <div className={styles.main_option_wrapper__title}>
                  <p>PARCEL DETAILS</p>
                </div>

                <div className={styles.main_option_wrapper__first}>
                  <div className={styles.main_option_wrapper__first_names}>
                    <div>Parcel Name</div>
                    <div>Air way bill</div>
                    <div>In My Way date</div>
                    <div>Freight forwarder</div>
                  </div>
                  <div className={styles.main_option_wrapper__first_details}>
                    <div>{item?.parcel?.parcel_name}</div>
                    <div>{item?.parcel?.air_way_bill}</div>
                    <div>{item?.parcel?.in_my_way_date}</div>
                    <div>{item?.parcel?.freight_forwarder}</div>
                  </div>
                </div>

                <div className={styles.main_option_wrapper__second}>
                  <div className={styles.main_option_wrapper__second_names}>
                    <div>Estimated Delivery Date</div>
                    <div>Totatl weight</div>
                    <div>V weight</div>
                  </div>
                  <div className={styles.main_option_wrapper__second_details}>
                    <div>{item?.parcel?.estimated_delivery_date}</div>
                    <div>{item?.parcel?.total_weight}</div>
                    <div>{item?.parcel?.v_weight}</div>
                  </div>
                </div>
              </div>

              <div className={styles.main_option_wrapper_cards__items}>
                <div className={styles.main_option_wrapper__title}>
                  <p>SHIPPING DETAILS</p>
                </div>

                <div className={styles.main_option_wrapper__first}>
                  <div className={styles.main_option_wrapper__first_names}>
                    <div>Full Name</div>
                    <div>Phone</div>
                    <div>Address line 1</div>
                    <div>Address line 2</div>
                  </div>
                  <div className={styles.main_option_wrapper__first_details}>
                    <div>{item?.receiver_full_name}</div>
                    <div>{item?.receiver_phone}</div>
                    <div>{item?.receiver_address_line1}</div>
                    <div>{item?.receiver_address_line2}</div>
                  </div>
                </div>

                <div className={styles.main_option_wrapper__second}>
                  <div className={styles.main_option_wrapper__second_names}>
                    <div>Estimated Delivery Date</div>
                    <div>Totatl weight</div>
                    <div>V weight</div>
                  </div>
                  <div className={styles.main_option_wrapper__second_details}>
                    <div>{item?.parcel?.estimated_delivery_date}</div>
                    <div>{item?.parcel?.total_weight}</div>
                    <div>{item?.parcel?.v_weight}</div>
                  </div>
                </div>
              </div>

              <div className={styles.main_option_wrapper_cards__items}>
                <div className={styles.main_option_wrapper__title}>
                  <p>PRODUCT</p>
                </div>

                <div className={styles.main_option_wrapper__first_product}>
                  <div className={styles.main_option_wrapper__first_names}>
                    <div>Pudo Reference</div>
                    <div>Product Description</div>
                    <div>Quantity</div>
                    <div>UnitPrice</div>
                  </div>
                  <div className={styles.main_option_wrapper__first_details}>
                    <div>{item?.pudo_reference}</div>
                    <div>{item?.product_description}</div>
                    <div>{item?.quantity}</div>
                    <div>{item?.unit_price}</div>
                  </div>
                </div>
              </div>

              <div className={styles.main_option_wrapper_cards__items}>
                <div className={styles.main_option_wrapper__title}>
                  <p>EVENTS</p>
                </div>
                <div
                  className={styles.main_option_wrapper_cards__items_line_event}
                ></div>
                <div className={styles.main_option_wrapper_cards__items_events}>
                  {eventsCard}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OptionDetails;
