import styled from "styled-components"

const Div = styled.div`
  border-bottom: ${({ border_b }) => border_b};
  background-color: ${({ bgc }) => bgc};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`
export default Div
