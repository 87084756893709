import React from "react";
import { Slider } from "./containers";
import Header from "./containers/Header";
import { PageRouter } from "./utils";
import { useLocation } from "react-router-dom";
function App() {
  const location = useLocation();

  const pathName = location.pathname
    .split("")
    .splice(1, location.pathname.length)
    .join("");
  return (
    <>
      <div>
        {pathName !== "login" && <Slider />}
        <Header />
        <PageRouter />
      </div>
    </>
  );
}

export default App;
