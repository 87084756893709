import React from "react";
import moment from "moment";

import "antd/dist/antd.css";

import { DatePicker } from "antd";


import calendar from "../../assets/images/calendar.svg";

const { RangePicker } = DatePicker;

const dateFormat = "YYYY-MM-DD";

const DataPickerGroup = ({ onChange }) => (
    <RangePicker
      onChange={onChange}
      defaultValue={[
        moment(new Date(), dateFormat),
        moment(new Date(), dateFormat),
      ]}
      format={dateFormat}
      suffixIcon={<img src={calendar} alt="" />}
      separator={"-"}
    />
  );

export default DataPickerGroup;
