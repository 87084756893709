import { AxiosInstance } from "../../utils/AxiosInstance"
import { IS_COLLED, GET_COUNTRY } from "../types"

export const setColled = (data) => async (dispatch) => {
  dispatch({
    type: IS_COLLED,
    payload: data,
  })
}

export const getCountry = () => async (dispatch) => {
  try {
    const { data } = await AxiosInstance.get(`admin/country/all`)
    dispatch({
      type: GET_COUNTRY,
      payload: data.data,
    })
  } catch (error) {
    console.log(error)
  }
}
