export const emptyObjectValue = (object) => {
  Object.keys(object).forEach((key) => {
    if (object[key] == "" || object[key] === null) {
      delete object[key];
    }
    return object;
  });
};

export const translateNumber = (input) => {
  const newInput = "" + input;
  return +newInput.replace(/\.(\d{1,2}).*$/, ".$1");
};
