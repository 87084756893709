import { delivery } from "../../utils";
import { AxiosInstance } from "../../utils/AxiosInstance";
import {
  ATTACHE_COURIER,
  ATTACHE_ERROR,
  CHANGE_DELIVERY_STATUS,
  EXPORT_ERROR,
  FILTER_DELIVERY,
  SEARCH_COURIER,
  SEARCH_DELIVERY,
  SET_DELIVERY,
} from "../types";

export const getDelivery = (perPage, page, companyValue) => async (dispatch) => {
  try {
    const { data } = await AxiosInstance.get(`admin/orders`, {
      params: {
        per_page:perPage,
        page: page,
        delivery: 1,
        ...companyValue
      },
    });
    dispatch({
      type: SET_DELIVERY,
      payload: data.data,
    });
  } catch (error) {}
};
export const fetchFilterDeliveryPN = (confirm) => async (dispatch) => {
  try {
    const { data } = await AxiosInstance.get(`admin/orders`, {
      params: {...confirm },
    });
    dispatch({
      type: SET_DELIVERY,
      payload: data.data,
    });
  } catch (error) {}
};
export const searchDelivery = (searchData) => async (dispatch) => {
  try {
    const { data } = await AxiosInstance.get(`admin/orders`, {
      params: {
        delivery: 1,
        ...searchData,
      },
    });

    dispatch({
      type: SEARCH_DELIVERY,
      payload: data,
    });
  } catch (error) {}
};

export const searchCourier = (id) => async (dispatch) => {
  if (id == 1000) {
    try {
      const { data } = await AxiosInstance.get(`admin/orders`, {
        params: {
          delivery: 1,
        },
      });
      dispatch({
        type: SET_DELIVERY,
        payload: data.data,
      });
    } catch (error) {}
  } else {
    try {
      const { data } = await AxiosInstance.get(`admin/orders`, {
        params: {
          courier_id: id,
        },
      });

      dispatch({
        type: SEARCH_COURIER,
        payload: data.data.data,
      });
    } catch (error) {
      console.log(error);
    }
  }
};

export const exportExcel = (id, parcels) => async (dispatch) => {
   try {
    let ID = id ? `excel/${id}`: "excel"
    const { data } = await AxiosInstance.get(`admin/courier/export/${ID}?parcels[]=${parcels}`,
      {
        responseType: "blob",
        method: "GET",
        headers: { "Content-Type": "application/vnd.ms-excel" },
      }
    )

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "file.xls");
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    dispatch({
      type: EXPORT_ERROR,
      payload: "Please select courier or Parcel Name",
    });

    setTimeout(() => {
      dispatch({
        type: EXPORT_ERROR,
        payload: "",
      });
    }, 2000);
  }
};

export const filretDeliveryDate = (filterData) => async (dispatch) => {
  try {
    const { data } = AxiosInstance.get(`admin/orders`, {
      params: {
        delivery: 1,
        start_at: filterData.start_at,
        end_at: filterData.end_at,
      },
    });

    delivery({
      type: FILTER_DELIVERY,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const attacheCourierOrder = (deliveryData) => async (dispatch) => {
  try {
    const { data } = await AxiosInstance.put(`admin/orders/update/all`, {
      data: [...deliveryData],
    });

    dispatch({
      type: ATTACHE_COURIER,
      payload: { data: data.data.data, message: "Success !!" },
    });

    setTimeout(() => {
      dispatch({
        type: ATTACHE_COURIER,
        payload: { data: data.data.data, message: "" },
      });
    }, 2000);
  } catch (error) {
    dispatch({
      type: ATTACHE_ERROR,
      payload: "Someting went wrong!",
    });
    setTimeout(() => {
      dispatch({
        type: ATTACHE_ERROR,
        payload: "",
      });
    }, 2000);
  }
};

export const changeDeliveryStatus = (id, delivery) => async (dispatch) => {
  try {
    const { data } = await AxiosInstance.put(`admin/orders/receive/${id}`);

    const indx = delivery.findIndex((item) => item.id == data.data.id);
    delivery.splice(indx, 1, data.data);
    dispatch({
      type: CHANGE_DELIVERY_STATUS,
      payload: {
        data: delivery,
        message: "Delivery status is Successfully changed!",
      },
    });

    setTimeout(() => {
      dispatch({
        type: CHANGE_DELIVERY_STATUS,
        payload: {
          data: delivery,
          message: "",
        },
      });
    }, 3000);
  } catch (error) {
    console.log(error);
  }
};
