import React from "react";

const ImageGroup = ({
  src,
  alt,
  onClick,
  className,
  placehoder,
  name,
  value,
  id,
}) => (
  <img
    id={id}
    name={name}
    value={value}
    src={src}
    alt={alt}
    onClick={onClick}
    className={className}
    placehoder={placehoder}
  />
);

export default ImageGroup;
