import styled from "styled-components"
import { Input } from "antd"

const InputSimple = styled(Input)`
  // background: ${({ bgc }) => bgc};
  width: ${({ width }) => width || "300px"};
  border-radius: 8px;
  margin-right: ${({ mr }) => mr};
  &:focus,
  &:hover,
  &:active {
    border-color: #5dba2f;
    box-shadow: #5dba2f;
    border-right-width: 1px;
    outline: 0;
  }
  .ant-input-focused {
    box-shadow: #5dba2f;
  }
`
export default InputSimple
