export const Eye = ({...props}) => {
  return (
      <div {...props} style={{cursor:"pointer", marginLeft: "-7px"}}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.65913 8.63233C6.34 9.58138 5.26407 10.7442 4.58385 11.57C4.37155 11.8278 4.37155 12.1722 4.58385 12.43C5.26407 13.2558 6.34 14.4186 7.65913 15.3677C8.98614 16.3224 10.4742 17 12 17C13.5258 17 15.0138 16.3224 16.3409 15.3677C17.66 14.4186 18.7359 13.2558 19.4161 12.43C19.6284 12.1722 19.6284 11.8278 19.4161 11.57C18.7359 10.7442 17.66 9.58138 16.3409 8.63233C15.0138 7.6776 13.5258 7 12 7C10.4742 7 8.98614 7.6776 7.65913 8.63233ZM6.4911 7.00884C7.99414 5.92747 9.89006 5 12 5C14.1099 5 16.0058 5.92747 17.5089 7.00885C19.0198 8.0959 20.22 9.40025 20.9599 10.2985C21.7805 11.2948 21.7805 12.7052 20.9599 13.7015C20.22 14.5998 19.0198 15.9041 17.5089 16.9912C16.0058 18.0725 14.1099 19 12 19C9.89006 19 7.99414 18.0725 6.4911 16.9912C4.98016 15.9041 3.77994 14.5998 3.04008 13.7015C2.21947 12.7052 2.21947 11.2948 3.04008 10.2985C3.77994 9.40024 4.98016 8.0959 6.4911 7.00884Z"
        fill="#B6B6B6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10ZM8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12Z"
        fill="#B6B6B6"
      />
    </svg>
      </div>
  );
};
