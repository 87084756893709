import { MANIFEST_BY_PARCEL_ID, SET_MANIFEST } from "../types";

const initialState = {
  manifest: [],
  oneParcelManifest: [],
  successMessage: "",
  errorMessage: "",
};

export const ManifestReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MANIFEST:
      return {
        ...state,
        manifest: action.payload,
      };

    case MANIFEST_BY_PARCEL_ID:
      return {
        ...state,
        oneParcelManifest: action.payload,
      };

    default:
      return state;
  }
};
