import React from "react";

const InputGroup = ({
  type,
  name,
  id,
  value,
  onChange,
  className,
  placeholder,
}) => (
  <input
    id={id}
    type={type}
    name={name}
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    className={className}
  />
);

export default InputGroup;
