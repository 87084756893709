import Item from "antd/lib/list/Item"
import axios from "axios"
import {useEffect} from "react"
import {AxiosInstance, AxiosInstance2} from "../../utils/AxiosInstance"
import {
    SET_PARCEL,
    UPDATER_PARCEL,
    CREATE_PARCEL,
    CREATE_PARCEL_ERROR,
    UPDATE_PARCEL_ERROR,
    GET_SINGLE_PARCEL,
    PARCEL_EXPORT_ERROR,
    FILTERED_PARCEL_DATA,
} from "../types"

export const getParcel = (perPage, page) => async (dispatch) => {
    try {
        const {data} = await AxiosInstance.get("admin/parcel", {
            params: {
                per_page: perPage,
                page,
            },
        })

        dispatch({
            type: SET_PARCEL,
            payload: data.data,
        })
    } catch (error) {
        console.log(error)
    }
}

export const createParcel = (param, parcelsList) => async (dispach) => {
    try {
        const {data} = await AxiosInstance.post(`admin/parcel`, param)
        const newParcel = [data.data, ...parcelsList]

        dispach({
            type: CREATE_PARCEL,
            payload: {data: newParcel, message: "Success!"},
        })

        setTimeout(() => {
            dispach({
                type: CREATE_PARCEL,
                payload: {data: newParcel, message: ""},
            })
        }, 2000)
    } catch (error) {
        dispach({
            type: CREATE_PARCEL_ERROR,
            payload: error,
        })

        setTimeout(() => {
            dispach({
                type: CREATE_PARCEL_ERROR,
                payload: {},
            })
        }, 5000)
    }
}

export const editParcel = (id, param, parcelsList) => async (dispatch) => {
    try {
        const {data} = await AxiosInstance.put(`admin/parcel/${id}`, param)
        const index = parcelsList.findIndex((item) => item.id == id)
        parcelsList.splice(index, 1, data.data)

        dispatch({
            type: UPDATER_PARCEL,
            payload: {data: parcelsList, message: "Success!"},
        })
        setTimeout(() => {
            dispatch({
                type: UPDATER_PARCEL,
                payload: {data: parcelsList, message: ""},
            })
        }, 4000)
    } catch (error) {
        dispatch({
            type: UPDATE_PARCEL_ERROR,
            payload: error,
        })
        setTimeout(() => {
            dispatch({
                type: UPDATE_PARCEL_ERROR,
                payload: "",
            })
        }, 5000)
    }
}

export const getReports = (params) => async (dispatch) => {
    try {
        const {data} = await AxiosInstance2.get(
            `admin/parcel/export/${params.parcel_id}`,
            {
                params: {
                    manifest: params.manifest,
                },
                responseType: "blob",
                method: "GET",
                headers: {"Content-Type": "application/vnd.ms-excel"},
            }
        )

        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "file.xls")
        document.body.appendChild(link)
        link.click()
        // const { data } = fetch(
        //   `https://api.onex.am/api/admin/parcel/export/2?manifest[]=15`,
        //   {
        //     // responseType: "blob",
        //     headers: {
        //       "Content-Type": "application/vnd.ms-excel",
        //       Accept: "application / vnd.ms - excel",
        //       Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FwaS5vbmV4LmFtL2FwaS9sb2dpbiIsImlhdCI6MTY2NTczOTUxMCwiZXhwIjoxNjY2NDU5NTEwLCJuYmYiOjE2NjU3Mzk1MTAsImp0aSI6IlFxN29BSDhtWE50Y3Zva3YiLCJzdWIiOiI2IiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.ALWCacm6jR_X9cnryp-c4WpHjzdeoF7KzDq0Vf3YF1M`,
        //     },
        //   }
        // )
        // let user = await data.json()
        // console.log(user)
        // try {
        //   console.log(data)

        //   console.log("dataa", typeof data, decodeURI(encodeURI(data)))
        // } catch (e) {
        //   console.info(e)
        // }
    } catch (error) {
        dispatch({
            type: PARCEL_EXPORT_ERROR,
            payload: "Please select Parcel!",
        })

        setTimeout(() => {
            dispatch({
                type: PARCEL_EXPORT_ERROR,
                payload: "",
            })
        }, 2000)
    }
}

export const getOneParcel = (id) => async (dispatch) => {
    try {
        const {data} = await AxiosInstance.get(`admin/parcel/${id}`)
        dispatch({
            type: GET_SINGLE_PARCEL,
            payload: data,
        })
    } catch (error) {
    }
}
//
export const filterParcelOrders =
    (perPage, page, filterDate) => async (dispatch) => {
        const {data} = await AxiosInstance.get("admin/parcel", {
            params: {
                per_page: perPage,
                page: page,
                start_at: filterDate.start_at,
                end_at: filterDate.end_at,
            },
        })

        dispatch({
            type: FILTERED_PARCEL_DATA,
            payload: data.data,
        })
    }


export const filterParcels = (compareData) => async (dispatch) => {
    const {data} = await AxiosInstance.get(`admin/parcel`, {
        params: compareData,
    })
    dispatch({
        type: FILTERED_PARCEL_DATA,
        payload: data.data,
    })
}