import {Select} from "antd";
import styled from "styled-components";

const {Option} = Select;
const SelectStyle = styled(Select)`
  width: ${({width}) => width || "100%"};
  margin: ${({margin}) => margin || "0"};

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
    border:${({hover})=>hover && "none" };
    background: ${({bg})=>bg || "#ffffff"};
    color: ${({color})=>color || "#262626"};
    font-family: 'Montserrat';
    &:focus,
    &:hover,
    &:active {
      border-color: #5dba2f;
      box-shadow: #5dba2f;
      border-right-width: 1px;
      outline: 0;
    }
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
    border-color: #5dba2f;
    box-shadow: 0 0 0 2px rgb(93 186 47 / 20%);
    border-right-width: 1px;
    outline: 0;
  }
`;

const InputSelect = ({
                         data = ["home", "Company", "delivery"],
                         value,
                         onChange = () => {
                         },
                         obj = false,
                         load=false,
                         ...props
                     }) => {
    const handleChange = (value) => {
        onChange(value);
    };
    let option = obj ? data : [...data].map((el, i) => ({
        ket: i,
        title: el,
        value: el ,
    }));
    return (
        <SelectStyle defaultValue={value && value.length>0 ? value : "All"} onChange={handleChange} {...props} loading={load}>
            {[
                {
                    ket: -1,
                    title: "All",
                    value: "",
                },
                ...option,
            ].map((opt, i) => (
                <Option className={"opt"} value={opt.value} key={i}>
                    {opt.title}
                </Option>
            ))}
        </SelectStyle>
    );
};
export default InputSelect;
