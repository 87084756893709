import {Eye} from "../../assets/svg-component/Eye";
import {SortingButtons} from "../../assets/svg-component/SortingButtons";
import {Popconfirm, Typography} from "antd";
import React from "react";
import InputSimple from "../elements/InputSimple";
import InputSelect from "../elements/InputSelect";
import EditInput from "../elements/edit-input"

export const columns = (isEditing, editingKey, cancel, edit, save, filterField, setFilterField, handleUpdateDeliveryType = () => {
}, handleShow = () => {
}, sorter, setSorter) => {

    let column = [
        {
            title: "",
            dataIndex: "icon",
            key: "icon",
            render: (_, record) => <Eye onClick={() => handleShow(record.ID)}/>,
            width: 32,
            fixed: 'left',
        },
        {
            title: () => (
                <div className={"t_title"}>
          <span className={"title_text"}>ID   <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                  setSorter((sorter) => sorter === direction ? "" : direction);
              }}
          /></span>

                    <InputSimple width={"100%"} value={filterField.id}
                                 onChange={(e) => setFilterField({...filterField, id: e.target.value})}/>
                </div>
            ),
            dataIndex: "ID",
            key: "ID",
            width: 70,
        },
        {
            title: () => (
                <div className={"t_title"}>
          <span className={"title_text"}>Full Name    <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                  setSorter((sorter) => sorter === direction ? "" : direction);
              }}
          /></span>
                    <InputSimple width={"100%"} value={filterField.receiver_full_name} onChange={(e) => setFilterField({
                        ...filterField,
                        receiver_full_name: e.target.value
                    })}/>
                </div>
            ),
            dataIndex: "full_name",
            key: "full_name",
            width: 120,
        },
        {
            title: () => (
                <div className={"t_title"}>
                    <span className={"title_text"}>Phone</span>
                    <InputSimple width={"100%"} value={filterField.receiver_phone}
                                 onChange={(e) => setFilterField({...filterField, receiver_phone: e.target.value})}/>
                </div>
            ),
            dataIndex: "receiver_phone",
            key: "receiver_phone",
            width: 165,
            editable: true,
        },
        {
            title: () => (
                <div className={"t_title"}>
          <span className={"title_text"}>Address line 1   <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                  setSorter((sorter) => sorter === direction ? "" : direction);
              }}
          /></span>
                    <InputSimple width={"100%"} value={filterField.receiver_address_line1}
                                 onChange={(e) => setFilterField({
                                     ...filterField,
                                     receiver_address_line1: e.target.value
                                 })}/>
                </div>
            ),
            dataIndex: "receiver_address_line1",
            key: "receiver_address_line1",
            width: 180,
            editable: true,
        },
        {
            title: () => (
                <div className={"t_title"}>
          <span className={"title_text"}>ID/passport<SortingButtons
              color="#757272"
              handleChange={(direction) => {
                  setSorter((sorter) => sorter === direction ? "" : direction);
              }}
          /></span>
                    <InputSimple width={"100%"} value={filterField.id_number}
                                 onChange={(e) => setFilterField({...filterField, id_number: e.target.value})}/>
                </div>
            ),
            dataIndex: "id_number",
            key: "id_number",
            width: 150,
            editable: true,
        },
        {
            title: () => (
                <div className={"t_title"}>
                    <span className={"title_text"}>Operation</span>
                </div>
            ),
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span style={{display: "flex"}}>
            <Typography.Link
                onClick={() => save(record.key)}
                style={{
                    marginRight: 8,
                }}
            >
              <span className={"edit"}>Save</span>
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <span className={"edit"}>Cancel</span>
            </Popconfirm>
          </span>
                ) : (
                    <Typography.Link
                        disabled={editingKey !== ""}
                        onClick={() => edit(record)}
                    >
            <span className={editingKey !== "" ? "edit_disabled" : "edit"}>
              Edit
            </span>
                    </Typography.Link>
                );
            },
            dataIndex: "operation",
            key: "operation",
            width: 100,
        },
        {
            title: () => (
                <div className={"t_title"}>
                    <span className={"title_text"}>Delivery Type</span>
                    <InputSelect value={filterField.delivery_type}
                                 data={["hayPost", "locker", "branch", "homeDelivery"]}
                                 onChange={(val) => setFilterField({...filterField, delivery_type: val})}/>
                </div>
            ),
            render: (_, record) => {
                return (<InputSelect hover={"true"} color={record.delivery_type === "PUDO" ? "#FC9A3A" : "#3FCC75"}
                                     bg={record.delivery_type === "PUDO" ? "#F7E3D1" : "#D2EDDD"}
                                     value={record.delivery_type} data={[{title: "hayPost", value: "hayPost"}, {
                    title: "homeDelivery",
                    value: "homeDelivery"
                }]}
                                     onChange={(val) => handleUpdateDeliveryType(record.ID, {delivery_type: val})}/>)
            },
            dataIndex: "delivery_type",
            key: "delivery_type",
            width: 180,
        },
        {
            title: () => (
                <div className={"t_title"}>
                    <span className={"title_text"}>Pick up point</span>
                    <InputSimple width={"100%"} value={filterField.pickup_point}
                                 onChange={(e) => setFilterField({...filterField, pickup_point: e.target.value})}/>
                </div>
            ),
            dataIndex: "pick_up_point",
            key: "pick_up_point",
            width: 250,
        },
        {
            title: () => (
                <div className={"t_title"}>
          <span className={"title_text"}>City   <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                  setSorter((sorter) => sorter === direction ? "" : direction);
              }}
          /></span>
                    <InputSimple width={"100%"} value={filterField.receiver_city}
                                 onChange={(e) => setFilterField({...filterField, receiver_city: e.target.value})}/>
                </div>
            ),
            dataIndex: "city",
            key: "city",
            width: 100,
        },
        {
            title: () => (
                <div className={"t_title"}>
          <span className={"title_text"}>State <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                  setSorter((sorter) => sorter === direction ? "" : direction);
              }}
          /></span>
                    <InputSelect value={filterField.receiver_state}
                                 onChange={(val) => setFilterField({...filterField, receiver_state: val})}/>
                </div>
            ),
            dataIndex: "state",
            key: "state",
            width: 140,
        },
        {
            title: () => (
                <div className={"t_title"}>
                    <span className={"title_text"}>Zip code/Hay post </span>
                    <InputSimple width={"100%"} value={filterField.receiver_zip}
                                 onChange={(e) => setFilterField({...filterField, receiver_zip: e.target.value})}/>
                </div>
            ),
            render: (_, record) => {
                return <EditInput variant={"receiver_zip"} record={record}/>
            },
            dataIndex: "receiver_zip",
            key: "receiver_zip",
            width: 200,
        },
        {
            title: () => (
                <div className={"t_title"}>
                    <span className={"title_text"}>Comments</span>
                </div>
            ),
            dataIndex: "support_comment",
            key: "support_comment",
            render: (_, record) => <EditInput variant={"support_comment"} record={record}/>,
            width: 250,
        },
        {
            title: () => (
                <div className={"t_title"}>
          <span className={"title_text"}>Tracking No  <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                  setSorter((sorter) => sorter === direction ? "" : direction);
              }}
          /> </span>
                    <InputSimple width={"100%"} value={filterField.tracking_number}
                                 onChange={(e) => setFilterField({...filterField, tracking_number: e.target.value})}/>
                </div>
            ),
            dataIndex: "tracking_no",
            key: "tracking_no",
            width: 190,
        },
        {
            title: () => (
                <div className={"t_title"}>
                    <span className={"title_text"}>Delivery Status</span>
                    <InputSimple width={"100%"} value={filterField.current_event}
                                 onChange={(e) => setFilterField({...filterField, current_event: e.target.value})}/>
                </div>
            ),
            dataIndex: "delivery_status",
            key: "delivery_status",
            width: 220,
        },
        {
            title: () => (
                <div className={"t_title"}>
                    <span className={"title_text"}>Status</span>
                    <InputSimple width={"100%"} value={filterField.status}
                                 onChange={(e) => setFilterField({...filterField, status: e.target.value})}/>
                </div>
            ),
            dataIndex: "status",
            key: "status",
            width: 150,
        },
        {
            title: () => (
                <div className={"t_title"}>
                    <span className={"title_text"}>Parcel Weight</span>
                    <InputSimple width={"100%"} value={filterField.parcel_weight}
                                 onChange={(e) => setFilterField({...filterField, parcel_weight: e.target.value})}/>
                </div>
            ),
            dataIndex: "parcel_weight",
            key: "parcel_weight",
            width: 125,
        },
        {
            title: () => (
                <div className={"t_title"}>
                    <span className={"title_text"}>Dimensions</span>
                    <InputSimple width={"100%"}/>
                </div>
            ),
            dataIndex: "dimensions",
            key: "dimensions",
            width: 170,
        },
        {
            title: () => (
                <div className={"t_title"}>
          <span className={"title_text"}>Shipped Date   <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                  setSorter((sorter) => sorter === direction ? "" : direction);
              }}
          /></span>
                    <InputSimple width={"100%"} value={filterField.shipped_date}
                                 onChange={(e) => setFilterField({...filterField, shipped_date: e.target.value})}/>
                </div>
            ),
            dataIndex: "shipped_date",
            key: "shipped_date",
            width: 150,
        },
        {
            title: () => (
                <div className={"t_title"}>
          <span className={"title_text"}>Parcel Name   <SortingButtons
              color="#757272"
              handleChange={(direction) => {
                  setSorter((sorter) => sorter === direction ? "" : direction);
              }}
          /></span>
                    <InputSimple width={"100%"} value={filterField.parcel}
                                 onChange={(e) => setFilterField({...filterField, parcel: e.target.value})}/>
                </div>
            ),
            dataIndex: "parcel_name",
            key: "parcel_name",
            width: 160,
            fixed: 'right',
        },
    ];
    return {column};
};
