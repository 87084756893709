import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { LoginReducer } from "./reducer";
import { ordersReducer } from "./reducer";
import { parcelReducer } from "./reducer";
import { ManifestReducer } from "./reducer";
import { CommonReducer } from "./reducer";
import { EventReducer } from "./reducer";
import { DeliveryReducer } from "./reducer";
import { CourierReducer } from "./reducer";
import { supportReducer } from "./reducer";
import {cargoReducer} from "./reducer/CargoReducer";
const rootReducer = combineReducers({
  LoginReducer: LoginReducer,
  ordersReducer: ordersReducer,
  parcelReducer: parcelReducer,
  ManifestReducer: ManifestReducer,
  CommonReducer: CommonReducer,
  EventReducer: EventReducer,
  CourierReducer: CourierReducer,
  DeliveryReducer: DeliveryReducer,
  support: supportReducer,
  cargo: cargoReducer,
});
// convert object to string and store in localStorage
function saveToLocalStorage(state) {
  try {
    const serialisedState = JSON.stringify(state);
    localStorage.setItem("persistantState", serialisedState);
  } catch (e) {
    console.warn(e);
  }
}

// load string from localStarage and convert into an Object
// invalid output must be undefined
function loadFromLocalStorage() {
  try {
    const serialisedState = localStorage.getItem("persistantState");
    if (serialisedState === null) return undefined;
    return JSON.parse(serialisedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}
const store = createStore(
  rootReducer,
    loadFromLocalStorage(),
  composeWithDevTools(applyMiddleware(thunk))
);
store.subscribe(() => saveToLocalStorage({parcelReducer: store.getState().parcelReducer}));

export default store;
