import { AxiosInstance } from "../../utils/AxiosInstance";
import {
  SET_COURIER,
  CREATE_COURIER,
  CREATE_COURIER_ERROR,
  REMOVE_COURIER,
  REMOVE_COURIER_ERROR,
} from "../types";

export const getCourier = () => async (dispatch) => {
  try {
    const { data } = await AxiosInstance.get(`admin/courier`);

    dispatch({
      type: SET_COURIER,
      payload: data,
    });
  } catch (error) {}
};

export const createCourier = (courierData, courier) => async (dispatch) => {
  try {
    const { data } = await AxiosInstance.post(`admin/courier`, courierData);
    const newData = [data.data, ...courier];
    dispatch({
      type: CREATE_COURIER,
      payload: { newData, message: "The courier is successfully added!" },
    });

    setTimeout(() => {
      dispatch({
        type: CREATE_COURIER,
        payload: { newData, message: "" },
      });
    }, 2000);
  } catch (error) {
  //   dispatch({
  //     type: CREATE_COURIER_ERROR,
  //     payload: "Name or Phone fields are not correct!",
  //   });
  //   setTimeout(() => {
  //     dispatch({
  //       type: CREATE_COURIER_ERROR,
  //       payload: "",
  //     });
  //   }, 3000);
  }
};

export const removeCourier = (id, couriers) => async (dispatch) => {
  try {
    const { data } = await AxiosInstance.delete(`admin/courier/${id}`);
    const newData = couriers.filter((item) => item.id != data.data.id);
    dispatch({
      type: REMOVE_COURIER,
      payload: { newData, message: "The Copurier is successfully deleted" },
    });

    setTimeout(() => {
      dispatch({
        type: REMOVE_COURIER,
        payload: { newData, message: "" },
      });
    }, 2000);
  } catch (error) {
    dispatch({
      type: REMOVE_COURIER_ERROR,
      payload: "Something went wrong!",
    });
  }
};
