import React from "react";

export const SortingButtons = (props) => {
  const { color,margin, handleChange = () => {} } = props;
  return (
    <svg style={{margin:"0 0 0 10px",cursor:"pointer"}}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 6L8 3L5 6"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        onClick={() => handleChange("-")}
      />
      <path
        d="M5 10L8 13L11 10"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        onClick={() => handleChange("+")}
      />
    </svg>
  );
};
