import { Button, Form, Select, message } from "antd"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import styles from "./company.module.scss"

import search from "../../assets/images/search.svg"
import { getEvent } from "../../store/actions/EventsAction"
import { searchOrder, setNewEvent, filterOrders } from "../../store/actions"

import {
  distributeOrders,
  getOrdersDetails,
  getOrders,
  getCompanies,
} from "../../store/actions"

import {
  ImageGroup,
  DataPickerGroup,
  TableItem,
  InputGroup,
  ModalGroup,
  ButtonGroup,
  OptionDetails,
  PaginationGroup,
} from "../../components/index"
import InputSelect from "../../components/elements/InputSelect";

const { Option } = Select

const Company = () => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(15)
  const [active, setActive] = useState(false)
  const [oneOrder, setOneOrder] = useState({})
  const [filterData, setFilterData] = useState({})
  const [companyValue, setCompanyValue] = useState({})
  const [selectedData, setSelectedData] = useState("")
  const [openOptionDetails, setOpenOptionDetails] = useState(false)
  const [parcelId, setParcelId] = useState("")
  const [inputValue, setInputValue] = useState({})
  const isActive = useSelector((state) => state.ordersReducer.bool)
  const orders = useSelector((state) => state.ordersReducer.orders)
  const order = useSelector((state) => state.ordersReducer.oneOrder)
  const company = useSelector((state) => state.ordersReducer.company)
  const allOrders = useSelector((state) => state.ordersReducer.allOrders)
  const parcelsList = useSelector((state) => state.parcelReducer.parcel);
  const distributeError = useSelector(
    (state) => state.ordersReducer.errorDistribute
  )
  const distributeSuccess = useSelector(
    (state) => state.ordersReducer.successDistribute
  )
  let selectData = [...parcelsList].map(name =>({
    ket: name.id,
    title: name.parcel_name,
    value:name.id

  }))
  const handleChangeParcelName= (val)=>{
    setParcelId(val)
    setPage(1)
    setPerPage(15)
    setInputValue({parcel_id:val})
    if(val){
      dispatch(getOrders(null, null, {parcel_id:val}))
    }else {
      dispatch(getOrders(null, null, {}))
    }
  }
  const isLoggedIn = localStorage.getItem("accessToken")
  const user = JSON.parse(localStorage.getItem("userInfo"))

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const totalOrders = allOrders?.meta?.total

  useEffect(() => {
    if (isLoggedIn == null) {
      navigate("/login")
    }
  }, [isLoggedIn, navigate, user])

  useEffect(() => {
    if (Object.entries(filterData).length > 0) {
      dispatch(filterOrders(10, 1, filterData, ""))
    }
  }, [filterData])

  useEffect(() => {
    if (Object.entries(companyValue).length == 0) {
      dispatch(getOrders(perPage, page))
    } else {
      dispatch(getOrders(perPage, page, companyValue))
    }
  }, [companyValue])

  useEffect(() => {
    dispatch(getCompanies())
  }, [])

  const handleChangecompany = (value, e) => {
    const { name } = e
    setCompanyValue(name !== "all" ? { company_id: value } : {})
  }

  const onChangeDate = (data, dateString) => {
    setFilterData({ start_at: dateString[0], end_at: dateString[1] })
  }

  const handleClick = (id) => {
    dispatch(getOrdersDetails(id))
    dispatch(getEvent())
    setOpenOptionDetails(!openOptionDetails)
  }

  const handleOpenModal = (id) => {
    const oneOrder = orders.find((item) => item.id == id)
    setOneOrder(oneOrder)
    setActive(!active)
  }

  const handleCnacel = () => {
    setActive(false)
  }

  const sentEvent = (event_code, e) => {
    e.preventDefault()

    dispatch(setNewEvent({ order_id: oneOrder.id, event_code }, orders))
  }

  const handleChangeSelect = (e) => {
    setSelectedData(e.target.value)
  }

  const handleSearch = () => {
    dispatch(searchOrder({ tracking_number: selectedData }))
  }

  const hamdleDistribute = () => {
    dispatch(distributeOrders({parcel_id:parcelId}))
  }

  const changePerPage = (item,value) => {
    const newParams = {};
    Object.entries(inputValue).forEach(([key, value]) => {
      if (value) {
        newParams[key] = value;
      }})
    setPage(item)
    setPerPage(value)

    dispatch(getOrders(value, item, newParams))
  }

  return (
    <section className={styles.main_branches}>
      <OptionDetails
        isopenModal={openOptionDetails}
        setIsOpenModal={setOpenOptionDetails}
        item={order}
      />
      {active && (
        <ModalGroup
          active={active}
          setActive={setActive}
          type="company"
          oneOrder={oneOrder}
          handleCnacel={handleCnacel}
          sentEvent={sentEvent}
        />
      )}
      <div
        className={`container ${
          isActive
            ? `main_branches_mobile_active  ${styles.main_branches__wrapper_active}`
            : `main_branches_mobile ${styles.main_branches__wrapper}`
        }`}
      >
        {distributeSuccess.length > 0 && message.success(distributeSuccess)}
        {distributeError && message.error(distributeError)}

        <br />
        <Form className={styles.main_branches__wrapper_items}>
          <div className={styles.main_branches__wrapper_items_left}>
            <InputGroup
              value={selectedData}
              onChange={handleChangeSelect}
              placeholder="Tracking code"
              className={styles.main_branches__wrapper_select}
            />
            <Button
              onClick={handleSearch}
              className={styles.main_branches__wrapper_button}
            >
              <ImageGroup src={search}></ImageGroup>
            </Button>
            <InputSelect width={"250px"} margin={"0 20px 0 20px"} data={selectData} value={"Parcel Name"} obj={true} onChange={(val)=>handleChangeParcelName(val)}/>

          </div>
          <div className={styles.main_branches__wrapper_sel}>
            <Select defaultValue={"All"} onChange={handleChangecompany}>
              <Option value="" name={"all"}>
                All
              </Option>
              {company.map(({ id, name }) => {
                return (
                  <Option key={id} value={id} name={name}>
                    {name}
                  </Option>
                )
              })}
            </Select>
          </div>
          <ButtonGroup
            onClick={hamdleDistribute}
            className={styles.main_branches__wrapper_button_distribute}
            disabled={parcelId ===""}
          >
            Distribute
          </ButtonGroup>
          <div className={styles.main_branches__wrapper_date}>
            <DataPickerGroup onChange={onChangeDate} />
          </div>
        </Form>

        <TableItem
          handleClick={handleClick}
          type="company"
          active={active}
          setActive={setActive}
          handleOpenModal={handleOpenModal}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />

        <div className={styles.pagination}>
          <PaginationGroup
            onChange={changePerPage}
            page={page}
            total={totalOrders}
            pageSize={perPage}
          />
        </div>
      </div>
    </section>
  )
}

export default Company
