export const TableHead = [
  { id: 0, name: "" },
  { id: 1, name: "ID" },
  { id: 2, name: "Parcel Name" },
  { id: 3, name: "Tracking No" },
  { id: 4, name: "Order No" },
  { id: 13, name: "Delivery Status" },
  { id: 6, name: "Full Name" },
  { id: 7, name: "Phone" },
  { id: 8, name: "Address line 1" },
  { id: 14, name: "Delivery Type" },
  { id: 9, name: "Pickup Point" },
  { id: 10, name: "City" },
  { id: 11, name: "State" },
  { id: 12, name: "Country" },
  { id: 5, name: "Status" },
  { id: 15, name: "Parcel Weight" },
  { id: 16, name: "Domentions" },
  { id: 17, name: "Shipped Date" },
  { id: 18, name: "ID Country" },
  { id: 19, name: "ID Number" },
  { id: 20, name: "ID Type" },
]
export const ParcelTableHead = [
  { id: 0, name: "" },
  { id: 1, name: "Parsel Name" },
  { id: 2, name: "Air Way Bill" },
  { id: 3, name: "In My Way Date" },
  { id: 4, name: "Freight Forwarder" },
  { id: 5, name: "Estimated Delivery Date" },
  { id: 6, name: "Total Weight" },
  { id: 7, name: "V Weight" },
]

export const manifesttableHead = [
  { id: 0, name: "" },
  { id: 1, name: "ID" },
  { id: 2, name: "Manifest Name" },
  { id: 3, name: "Box Weight" },
  { id: 4, name: "WH Orders Count" },
  { id: 5, name: "EH Orders Total Weight" },
]

export const delivery = [
  { id: 0, name: "" },
  { id: 1, name: "ID" },
  { id: 21, name: "Courier" },
  { id: 2, name: "Full Name" },
  { id: 7, name: "Phone" },
  { id: 8, name: "Address line 1" },
  { id: 14, name: "Delivery Type" },
  { id: 9, name: "Pickup Point" },
  { id: 10, name: "City" },
  { id: 11, name: "State" },
  { id: 3, name: "Tracking No" },
  { id: 4, name: "Order No" },
  { id: 22, name: "Delivery Status " },
  { id: 6, name: "Parcel Name" },
  { id: 15, name: "status" },
  { id: 13, name: "Event" },
  { id: 12, name: "Parcel Weight" },
  { id: 16, name: "Domentions" },
  { id: 17, name: "Shipped Date" },
  { id: 18, name: "ID Country" },
  { id: 19, name: "ID Number" },
  { id: 20, name: "ID Type" },
]

export const branches = [
  { id: 0, name: "" },
  { id: 1, name: "ID" },
  { id: 2, name: "Full Name" },
  { id: 7, name: "Phone" },
  { id: 8, name: "Address line 1" },
  { id: 14, name: "Delivery Type" },
  { id: 9, name: "Pickup Point" },
  { id: 10, name: "City" },
  { id: 11, name: "State" },
  { id: 3, name: "Tracking No" },
  { id: 4, name: "Order No" },
  { id: 21, name: "Delivery Status" },
  { id: 6, name: "Parcel Name" },
  { id: 12, name: "Country" },
  { id: 5, name: "Status" },
  // { id: 13, name: "Event" },
  { id: 15, name: "Parcel Weight" },
  { id: 16, name: "Domentions" },
  { id: 17, name: "Shipped Date" },
  { id: 18, name: "ID Country" },
  { id: 19, name: "ID Number" },
  { id: 20, name: "ID Type" },
]
