import styled from "styled-components"

const ContainerWrapper = styled.div`
  padding: ${({ padding }) => padding};
  height: ${({ height }) => height};
  transition: ${({ transition }) => transition};
  width: 100%;
  max-width: 1877px;
  margin: 0 auto;
  max-height: 100vh;
  scrollbar-width: none;
`
export default ContainerWrapper
