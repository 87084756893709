export const MANIFEST_BY_PARCEL_ID = "MANIFEST_BY_PARCEL_ID";
export const ORDERS_BY_MANIFEST_ID = "ORDERS_BY_MANIFEST_ID";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const SET_ORDERS = "SET_ORDERS";
export const SET_ORDERS_ERROR = "SET_ORDERS_ERROR";
export const SET_ORDERS_DETAILS = "SET_ORDERS_DETAILS";
export const SET_PARCEL = "SET_PARCEL";
export const SET_PARCEL_ERROR = "SET_PARCEL_ERROR";
export const SEARCH_ORDERS = "SEARCH_ORDERS";
export const SET_MANIFEST = "SET_MANIFEST";
export const SET_BOOL = "SET_BOOL";
export const UPDATER_PARCEL = "UPDATER_PARCEL";
export const CREATE_PARCEL = "CREATE_PARCEL";
export const CREATE_PARCEL_ERROR = "CREATE_PARCEL_ERROR";
export const UPDATE_PARCEL_ERROR = "UPDATE_PARCEL_ERROR";
export const FILTERED_DATAS = "FILTERED_DATAS";
export const GET_EVENTS = "GET_EVENTS";
export const IS_COLLED = "IS_COLLED";
export const SET_DELIVERY = "SET_DELIVERY";
export const SET_COURIER = " SET_COURIER";
export const CREATE_COURIER = "CREATE_COURIER";
export const CREATE_COURIER_ERROR = "CREATE_COURIER_ERROR";
export const REMOVE_COURIER = "REMOVE_COURIER";
export const REMOVE_COURIER_ERROR = "REMOVE_COURIER_ERROR";
export const SEARCH_COURIER = "SEARCH_COURIER";
export const SEARCH_DELIVERY = "SEARCH_DELIVERY";
export const EXPORT_ERROR = "EXPORT_ERROR";
export const FILTER_DELIVERY = "FILTER_DELIVERY";
export const ATTACHE_ERROR = " ATTACHE_ERROR";
export const GET_SINGLE_PARCEL = "GET_SINGLE_PARCEL";
export const PARCEL_EXPORT_ERROR = "PARCEL_EXPORT_ERROR";
export const ATTACHE_COURIER = "ATTACHE_COURIER";
export const SET_NEW_EVENT = "SET_NEW_EVENT";
export const SET_NEW_EVENT_ERROR = "SET_NEW_EVENT_ERROR";
export const FILTERED_PARCEL_DATA = "FILTERED_PARCEL_DATA";
export const CHANGE_DELIVERY_STATUS = "CHANGE_DELIVERY_STATUS";
export const DISTRIBUTE_ORDERS_ERROR = "DISTRIBUTE_ORDERS_ERROR";
export const DISTRIBUTE_ORDERS = "DISTRIBUTE_ORDERS";
export const GET_COMPANY = "GET_COMPANY";
export const GET_COUNTRY = "GET_COUNTRY";
export const FETCH_SUPPORT_DATA = "FETCH-SUPPORT-DATA";
export const FETCH_SUPPORT_DATA_SUCCESS = "FETCH-SUPPORT-DATA-SUCCESS";
export const FETCH_SUPPORT_DATA_ERROR = "FETCH-SUPPORT-DATA-ERROR";
export const UPDATE_SUPPORT_DATA_ERROR = "UPDATE_SUPPORT_DATA_ERROR";
export const RECEIVE_ORDERS = "RECEIVE_ORDERS";
export const RECEIVE_ORDERS_SUCCESS = "RECEIVE_ORDERS_SUCCESS";
export const RECEIVE_ORDERS_ERROR = "RECEIVE_ORDERS_ERROR";
export const ADD_COURIER_TO_ORDERS = "ADD_COURIER_TO_ORDERS";
export const ADD_COURIER_TO_ORDERS_SUCCESS = "ADD_COURIER_TO_ORDERS_SUCCESS";
export const ADD_COURIER_TO_ORDERS_ERROR = "ADD_COURIER_TO_ORDERS_ERROR";
export const CHANGE_PARCEL_EVENT = "CHANGE_PARCEL_EVENT";
export const CHANGE_PARCEL_EVENT_SUCCESS = "CHANGE_PARCEL_EVENT_SUCCESS";
export const CHANGE_PARCEL_EVENT_ERROR = "CHANGE_PARCEL_EVENT_ERROR";
export const FETCH_CARGO = "FETCH_CARGO";