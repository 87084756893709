import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import styles from "./parcelTable.module.scss";

import ImageGroup from "../ImageGroup";
import eye from "../../assets/images/eye.svg";
import edit from "../../assets/images/edit.svg";

const ParcelTable = ({ tableHead, handleClickEdit, type, getDataById }) => {
  const parcelsList = useSelector((state) => state.parcelReducer.parcel);
  const manifestList = useSelector((state) => state.ManifestReducer.manifest);
  const manifesteByParcelId = useSelector(
    (state) => state.ManifestReducer.oneParcelManifest
  );

  const headers = tableHead.map(({ id, name }) => {
    return (
      <div key={id} className={styles.main_table__head}>
        {name}
      </div>
    );
  });

  const manifests =
    manifesteByParcelId?.length > 0
      ? manifesteByParcelId?.map((item) => {
          return (
            <>
              <div key={item.id} className={styles.main_table__icons}>
                <Link to={"/"}>
                  <ImageGroup src={eye} onClick={() => getDataById(item.id)} />
                </Link>
              </div>
              <div className={styles.main_table__parcels}>{item.id}</div>
              <div className={styles.main_table__parcels}>tracking code</div>
              <div className={styles.main_table__parcels}>box weight</div>
              <div className={styles.main_table__parcels}>WH Orders Count</div>
              <div className={styles.main_table__parcels}>
                WH Orders Total Weight
              </div>
            </>
          );
        })
      : (manifestList.length > 0 &&
          manifestList?.map((item) => {
            return (
              <>
                <div key={item.id} className={styles.main_table__icons}>
                  <Link to={"/"}>
                    <ImageGroup
                      src={eye}
                      onClick={() => getDataById(item.id)}
                    />
                  </Link>
                </div>
                <div className={styles.main_table__parcels}>{item.id}</div>
                <div className={styles.main_table__parcels}>{item.name}</div>
                <div className={styles.main_table__parcels}>box weight</div>
                <div className={styles.main_table__parcels}>
                  WH Orders Count
                </div>
                <div className={styles.main_table__parcels}>
                  WH Orders Total Weight
                </div>
              </>
            );
          })) || <p className={styles.main_table__empty}>No data</p>;

  const parcels =
    parcelsList?.length > 0 ? (
      parcelsList?.map((item) => {
        return (
          <>
            <div key={item.id} className={styles.main_table__icons}>
              <Link to={"/manifest"}>
                <ImageGroup src={eye} onClick={() => getDataById(item.id)} />
              </Link>
              <ImageGroup
                src={edit}
                onClick={() => handleClickEdit(item.id)}
                className={styles.main_table__icons_edit}
              />
            </div>
            <div className={styles.main_table__parcels}>{item.parcel_name}</div>
            <div className={styles.main_table__parcels}>
              {item.air_way_bill}
            </div>
            <div className={styles.main_table__parcels}>
              {item.in_my_way_date}
            </div>
            <div className={styles.main_table__parcels}>
              {item.freight_forwarder}
            </div>
            <div className={styles.main_table__parcels}>
              {item.estimated_delivery_date}
            </div>
            <div className={styles.main_table__parcels}>
              {item.total_weight}
            </div>
            <div className={styles.main_table__parcels}>{item.v_weight}</div>
          </>
        );
      })
    ) : (
      <p className={styles.main_table__empty}>No Data</p>
    );

  return (
    <div style={{ width: "100%" }}>
      {type == "manifest" ? (
        <div className={` ${styles.main_table__manifest}`}>
          {headers}
          {manifests}
        </div>
      ) : (
        <div className={` ${styles.main_table}`}>
          {headers} {parcels}
        </div>
      )}
    </div>
  );
};

export default ParcelTable;
