import React, {useEffect, useState} from "react"
import {Select, Button, Form, message, Popconfirm} from "antd"
import {useNavigate} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"

import styles from "../Delivery/delivery.module.scss"
import {getEvent} from "../../store/actions/EventsAction"
import {
    ModalGroup,
    OptionDetails,
} from "../../components/index"
import {
    exportExcel,
    attacheCourierOrder,
} from "../../store/actions/DeliveryAction"
import {
    createCourier, getCargoData,
    getCourier,
    getOrdersDetails, getParcel, searchOrder,
} from "../../store/actions"
import {addCourierToOrders, fetchSupportDetails, receiveOrders} from "../../store/actions/SupportAction";
import {Eye} from "../../assets/svg-component/Eye";
import {SortingButtons} from "../../assets/svg-component/SortingButtons";
import {InputSimple} from "../../components/elements";
import AntDataTable from "../../components/AntTable";
import {debounceHook} from "../../hooks/useDebounce";
import {SelectForTable} from "../../components/elements/SelectForTable";
import styled from "styled-components";
const {Option} = Select
const debouncedFilter = debounceHook((fn) => fn(), 500);
const StyledSelect = styled.div `
  margin-right: 50px;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
    border: ${({hover}) => hover && "none"};
    background: ${({bg}) => bg || "#ffffff"};
    color: ${({color}) => color || "#262626"};
    font-family: 'Montserrat';

    &:focus,
    &:hover,
    &:active {
      border-color: #5dba2f;
      box-shadow: #5dba2f;
      border-right-width: 1px;
      outline: 0;
    }
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
    border-color: #5dba2f;
    box-shadow: 0 0 0 2px rgb(93 186 47 / 20%);
    border-right-width: 1px;
    outline: 0;
  }
`
const Cargo = () => {
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(100)
    const [active, setActive] = useState(false)
    const [oneOrder, setOneOrder] = useState({})
    const [filterData, setFilterData] = useState({})
    const [searchValue, setSearchValue] = useState("")
    let [courierValues, setCourierValues] = useState([])
    const [activeModal, setActiveModal] = useState(false)
    const [isopenModal, setIsOpenModal] = useState(false)
    const [inputValue, setInputValue] = useState({})
    const [courierData, setCourierData] = useState({
        full_name: "",
        phone: "",
    })

    const isActive = useSelector((state) => state.ordersReducer.bool)
    const order = useSelector((state) => state.ordersReducer.oneOrder)
    const courier = useSelector((state) => state.CourierReducer.courier)
    const delivery = useSelector((state) => state.DeliveryReducer.delivery)
    const saveError = useSelector((state) => state.DeliveryReducer.saveError)
    const success = useSelector((state) => state.DeliveryReducer.successmessage)
    const successSave = useSelector((state) => state.DeliveryReducer.saveMessage)
    const exportError = useSelector((state) => state.DeliveryReducer.errorMessage)
    const parcelsList = useSelector((state) => state.parcelReducer.parcel);
    const allDeliveryData = useSelector(
        (state) => state.DeliveryReducer.allDeliveryData
    )
    const dispatch = useDispatch()
    const isLoggedIn = localStorage.getItem("accessToken")
    const user = JSON.parse(localStorage.getItem("userInfo"))
    const navigate = useNavigate()
    let selectData = [...parcelsList].map(name => ({
        ket: name.id,
        title: name.parcel_name,
        value: name.id
    }));

    const {data = [], load, allData, courierChanged} = useSelector((state) => state.support);
    const cargoData = useSelector((state) => state.cargo);
    const [isActiveOptionDetails, setIsActiveOptionDetails] = useState(false);
    const [sorter, setSorter] = useState("");
    const [filterValue, setFilterValue] = useState("");
    const [filterFields, setFilterFields] = useState({});
    const [selectedRowKeys, setSelectedKeyRows] = useState([]);
    const [parcelNames, setParcelNames] = useState([]);

    useEffect(() => {
        if (isLoggedIn == null) {
            navigate("/login")
        }
    }, [isLoggedIn, navigate, user])

    useEffect(() => {
        // dispatch(getDelivery(perPage, page))
        dispatch(getParcel(50, 1));
        dispatch(getCourier())
        dispatch(fetchSupportDetails({page: page, per_page: 50}));
    }, [dispatch, success])

    const changePerPage = (item, value) => {
        const newParams = {};
        Object.entries(filterFields).forEach(([key, value]) => {
            if (value) {
                newParams[key] = value;
            }
        });
        setPage(item);
        setPerPage(value);
        let pageList = {page: item, per_page: value, delivery: 1, ...newParams};
        dispatch(fetchSupportDetails(pageList));
    };

    const handleActive = () => {
        setActive(!active)
    }

    const handleCancel = () => {
        setActive(false)
    }

    const addCourier = (courierData) => {
        dispatch(createCourier(courierData, courier))

    }
    const handleChangeParcelName = (val) => {
        setPage(1)
        setPerPage(1000)
        setInputValue({parcel_id: val})
        let parcelIds = [];

        if (val) {
            filterFields['parcels'] = val;
            filterFields['per_page'] = 1000;
            filterFields['page'] = 1;
            filterFields['delivery'] = 1;
            setParcelNames(val);
            dispatch(fetchSupportDetails(filterFields))
        } else {
            delete filterFields['parcels'];
            dispatch(fetchSupportDetails(filterFields))
        }

    }


    const couriersList = courier.map((item) => {
        return (
            <Option key={item.id} value={item.id}>
                {item.full_name}
            </Option>
        )
    })
    const onChangeSearch = (value) => {
        setSearchValue(value)
    }

    const handleSearchCourier = () => {
        if (searchValue !== "") {
            filterFields['delivery'] = 1;
            filterFields['courier_id'] = searchValue;
        } else {
            delete filterFields['courier_id'];
            filterFields['delivery'] = 1;
        }
        dispatch(fetchSupportDetails(filterFields));

    }
    //
    const handleExport = () => {
        console.log(filterFields['parcels']);
        dispatch(exportExcel(searchValue, filterFields['parcels']))
    }

    const onChangeDate = (data, dateString) => {
        setFilterData({start_at: dateString[0], end_at: dateString[1]});
        if (filteredData) filterFields['date'] = filteredData;
        else delete filterFields['date'];

        dispatch(fetchSupportDetails(filterFields));
    }

    const handleSave = () => {
        dispatch(attacheCourierOrder(courierValues))
    }
    const handleShowDetails = (id) => {
        dispatch(getOrdersDetails(id));
        dispatch(getEvent());
        setIsActiveOptionDetails(true);
    };

    const enterSearchInput = (e, field, type = "input") => {

        debouncedFilter(() => {
            if (e.target.value !== "") {
                filterFields[field] = e.target.value;
            } else {
                delete filterFields[field];
            }
            handleSearchOrder();
        });

        if (type === "select") {
            if (e !== "") {
                filterFields[field] = e;
            } else {
                delete filterFields[field];
            }
            handleSearchOrder();
        }
    };
    const handleChangeFilterField = (e) => {
        setFilterValue(e.target.value);
        if (e.target.value === "") {
            handleSearchOrder();
        }
    };

    const handleSearchOrder = () => {
        if (Object.keys(filterFields).length === 0) {
            dispatch(fetchSupportDetails(perPage, page));
        } else {
            // filterFields['delivery'] = 1;
            setPerPage(1000);
            setPage(1);
            dispatch(fetchSupportDetails(filterFields));

        }
    };

    const onRowSelectChange = (newSelectedRowKeys) => {
        setSelectedKeyRows(newSelectedRowKeys);
    }

    const rowSelection = {
        type: 'checkbox',
        onChange: onRowSelectChange
    };

    const handleDeleteRows = () => {
        let selectedItems = selectedRowKeys;
        dispatch(receiveOrders(selectedItems));
        filterFields['delivery'] = 1;
        dispatch(fetchSupportDetails(filterFields));
    }

    const chooseCourier = () => {
        // selectedRowKeys;
        filterFields['orders'] = selectedRowKeys;
        filterFields['courier_id'] = searchValue;
        delete filterFields['parcel_id'];
        if (searchValue) {
            dispatch(addCourierToOrders(filterFields)).then(() => {
                reloadDeliveryData();
            })
        }
    }

    const reloadDeliveryData = () => {
        delete filterFields['orders'];
        delete filterFields['courier_id'];
        filterFields['parcel_id'] = inputValue.parcel_id;
        dispatch(fetchSupportDetails(filterFields));
    }

    const filteredData = [...data].map(
        (
            {
                id,
                receiver_full_name,
                courier,
                receiver_phone,
                receiver_address_line1,
                delivery_type,
                pickup_point,
                receiver_city,
                receiver_state,
                tracking_number,
                order_number,
                status,
                parcel_weight,
                parcel_width,
                parcel_length,
                parcel_height,
                shipped_date,
                id_country,
                id_number,
                id_type,
                current_event,
                destination_status,
                destination_type,
                parcel,
                is_cargo_type
            },
        ) => {
            return {
                key: id,
                id: id,
                courier: courier,
                fullName: receiver_full_name,
                phone: receiver_phone,
                address: receiver_address_line1,
                deliveryType: delivery_type,
                pickupPoint: pickup_point?.name,
                city: receiver_city,
                state: receiver_state,
                trackingNo: tracking_number,
                orderNo: order_number,
                deliveryStatus: "",
                status: status,
                parcelWeight: parcel_weight,
                domentions: `H: ${parcel_height}, L: ${parcel_length}, W: ${parcel_width}`,
                shippedDate: shipped_date,
                idCountry: id_country,
                idNumber: id_number,
                idType: id_type,
                event: current_event?.event_description,
                destination_status,
                destination_type,
                parcel: parcel?.parcel_name,
                is_cargo_type: is_cargo_type
            }
        }
    );

    let dataSource = filteredData;


    if (sorter) {
        dataSource = [...filteredData].sort((a, b) => {
            if (a.id > b.id) {
                return sorter === "+" ? 1 : -1;
            }
            if (a.id < b.id) {
                return sorter === "+" ? -1 : 1;
            }
            return 0;
        });
    }

    const changeCargoStatus = (value, id) => {
        dispatch(getCargoData({id,type:value})).then(() => {
            message.success("Saved !")
        })
    }


    const columns = [
        {
            title: "",
            dataIndex: "icon",
            key: "icon",
            render: (_, record) => (
                <Eye key={record.id} onClick={() => handleShowDetails(record.id)}/>
            ),
            width: 32,
            fixed: "left",
        },
        {
            dataIndex: "trackingNo",
            title: () => (
                <div className={""}>
                <span className={"title_text"}>
                    Tracking No{" "}
                    <SortingButtons
                        color="#757272"
                        handleChange={(direction) => {
                            setSorter((sorter) => (sorter === direction ? "" : direction));
                        }}
                    />
                </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "tracking_number")}
                    />
                </div>
            ),
        },

        {
            dataIndex: "parcel",
            title: () => (
                <div className={""}>
                <span className={"title_text"}>
                    Parcel Name{" "}
                    <SortingButtons
                        color="#757272"
                        handleChange={(direction) => {
                            setSorter((sorter) => (sorter === direction ? "" : direction));
                        }}
                    />
                </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "parcel_name")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "fullName",
            title: () => (
                <div className={""}>
                <span className={"title_text"}>
                    Full Name{" "}
                    <SortingButtons
                        color="#757272"
                        handleChange={(direction) => {
                            setSorter((sorter) => (sorter === direction ? "" : direction));
                        }}
                    />
                </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "receiver_full_name")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "idNumber",
            title: () => (
                <div className={""}>
                <span className={"title_text"}>
                    ID Number{" "}
                    <SortingButtons
                        color="#757272"
                        handleChange={(direction) => {
                            setSorter((sorter) => (sorter === direction ? "" : direction));
                        }}
                    />
                </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "id_number")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "phone",
            title: () => (
                <div className={""}>
                <span className={"title_text"}>
                    Phone{" "}
                    <SortingButtons
                        color="#757272"
                        handleChange={(direction) => {
                            setSorter((sorter) => (sorter === direction ? "" : direction));
                        }}
                    />
                </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "receiver_phone")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "address",
            title: () => (
                <div className={""}>
                <span className={"title_text"}>
                    Address Line 1{" "}
                    <SortingButtons
                        color="#757272"
                        handleChange={(direction) => {
                            setSorter((sorter) => (sorter === direction ? "" : direction));
                        }}
                    />
                </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "receiver_address_line1")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "is_cargo_type",
            title: () => (
                <div className={""}>
                <span className={"title_text"}>
                    Cargo Status{" "}

                </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "receiver_address_line1")}
                    />
                </div>
            ),

            render: (_, record) => (

              <Select style={{width: 100}} defaultValue={record.is_cargo_type} onChange={(e) => changeCargoStatus(e, record.id)}>
                  <Option value="in_custom">IN CUSTOM</Option>
                  <Option value="double_check">DOUBLE CHECK</Option>
                  <Option value="cargo_out">CARGO OUT</Option>
              </Select>
            )
        },
        {
            dataIndex: "pickupPoint",
            title: () => (
                <div className={""}>
                <span className={"title_text"}>
                    Pickup Point{" "}
                    <SortingButtons
                        color="#757272"
                        handleChange={(direction) => {
                            setSorter((sorter) => (sorter === direction ? "" : direction));
                        }}
                    />
                </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "pudo_reference")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "city",
            title: () => (
                <div className={""}>
                <span className={"title_text"}>
                    City{" "}
                    <SortingButtons
                        color="#757272"
                        handleChange={(direction) => {
                            setSorter((sorter) => (sorter === direction ? "" : direction));
                        }}
                    />
                </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "receiver_city")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "state",
            title: () => (
                <div className={""}>
                <span className={"title_text"}>
                    State{" "}
                    <SortingButtons
                        color="#757272"
                        handleChange={(direction) => {
                            setSorter((sorter) => (sorter === direction ? "" : direction));
                        }}
                    />
                </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "receiver_state")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "orderNo",
            title: () => (
                <div className={""}>
                <span className={"title_text"}>
                    Order No{" "}
                    <SortingButtons
                        color="#757272"
                        handleChange={(direction) => {
                            setSorter((sorter) => (sorter === direction ? "" : direction));
                        }}
                    />
                </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "order_number")}
                    />
                </div>
            ),
        },

        {
            dataIndex: "status",
            title: () => (
                <div className={""}>
                <span className={"title_text"}>
                    Status{" "}
                    <SortingButtons
                        color="#757272"
                        handleChange={(direction) => {
                            setSorter((sorter) => (sorter === direction ? "" : direction));
                        }}
                    />
                </span>
                    <SelectForTable
                        options={["scanned", "at_warehouse"]}
                        enterSearchInput={enterSearchInput}
                        fieldName={"status"}
                    />
                </div>
            ),
        },
        {
            dataIndex: "event",
            title: () => (
                <div className={""}>
                <span className={"title_text"}>
                    Event{" "}
                    <SortingButtons
                        color="#757272"
                        handleChange={(direction) => {
                            setSorter((sorter) => (sorter === direction ? "" : direction));
                        }}
                    />
                </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "status")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "parcelWeight",
            title: () => (
                <div className={""}>
                <span className={"title_text"}>
                    Parcel Weight{" "}
                    <SortingButtons
                        color="#757272"
                        handleChange={(direction) => {
                            setSorter((sorter) => (sorter === direction ? "" : direction));
                        }}
                    />
                </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "parcel_weight")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "domentions",
            title: () => (
                <div className={""}>
                <span className={"title_text"}>
                    Domentions{" "}
                    <SortingButtons
                        color="#757272"
                        handleChange={(direction) => {
                            setSorter((sorter) => (sorter === direction ? "" : direction));
                        }}
                    />
                </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "domentions")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "shippedDate",
            title: () => (
                <div className={""}>
                <span className={"title_text"}>
                    Shipped Date{" "}
                    <SortingButtons
                        color="#757272"
                        handleChange={(direction) => {
                            setSorter((sorter) => (sorter === direction ? "" : direction));
                        }}
                    />
                </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "shipped_date")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "idCountry",
            title: () => (
                <div className={""}>
                <span className={"title_text"}>
                    ID Country{" "}
                    <SortingButtons
                        color="#757272"
                        handleChange={(direction) => {
                            setSorter((sorter) => (sorter === direction ? "" : direction));
                        }}
                    />
                </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "id_country")}
                    />
                </div>
            ),
        },
        {
            dataIndex: "idType",
            title: () => (
                <div className={""}>
                <span className={"title_text"}>
                    ID Type{" "}
                    <SortingButtons
                        color="#757272"
                        handleChange={(direction) => {
                            setSorter((sorter) => (sorter === direction ? "" : direction));
                        }}
                    />
                </span>
                    <InputSimple
                        width={"100%"}
                        onChange={(e) => handleChangeFilterField(e)}
                        onKeyDown={(e) => enterSearchInput(e, "id_type")}
                    />
                </div>
            ),
        },
    ]

    return (
        <>
            {
                active ? (
                    <ModalGroup
                        setActive={setActive}
                        courierData={courierData}
                        setCourierData={setCourierData}
                        type="delivery"
                        handleCnacel={handleCancel}
                        addData={addCourier}
                    />
                ) : null
            }
            <section className={styles.main_delivery}>
                <div
                    className={`container
       ${
                        isActive
                            ? `main_delivry_mobile_active ${styles.main_delivery__wrapper_active}`
                            : `main_delivry_mobile ${styles.main_delivery__wrapper}`
                    }    
     `}
                >
                    {successSave && message.success(successSave)}
                    {saveError && message.error(saveError)}
                    {exportError && message.error(exportError)}


                    <div style={{height: "20vh"}}>


                        <OptionDetails
                            isopenModal={isActiveOptionDetails}
                            setIsOpenModal={setIsActiveOptionDetails}
                            item={order}
                        />
                        <AntDataTable
                            columns={columns}
                            dataSource={dataSource}
                            loading={load}
                            perPage={perPage}
                            page={page}
                            changePerPage={changePerPage}
                            total={allData}
                            wrapper={false}
                            rowSelection={rowSelection}
                            pagination={true}
                        />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Cargo
