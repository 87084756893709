import {
   FETCH_CARGO
} from "../types";

const initialState = {
    data:[],
};

export const cargoReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CARGO:
            return {
                ...state,
                data:action.payload.data,
                success: true,
            };

        default:
            return state;
    }
};
