import { SET_MANIFEST, MANIFEST_BY_PARCEL_ID } from "../types";
import { AxiosInstance } from "../../utils/AxiosInstance";

export const getManifest = () => async (dispatch) => {
  try {
    const { data } = await AxiosInstance.get(`/admin/manifest`);
    dispatch({
      type: SET_MANIFEST,
      payload: data.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getManifestByParcelId = (id) => async (dispatch) => {
  try {
    const { data } = await AxiosInstance.get(`admin/manifest`, {
      params: {
        parcel_id: id,
      },
    });
    dispatch({
      type: MANIFEST_BY_PARCEL_ID,
      payload: data.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};
