import { AxiosInstance } from "../../utils/AxiosInstance";
import {
  ADD_COURIER_TO_ORDERS,
  ADD_COURIER_TO_ORDERS_ERROR,
  ADD_COURIER_TO_ORDERS_SUCCESS,
  CHANGE_PARCEL_EVENT,
  CHANGE_PARCEL_EVENT_ERROR,
  CHANGE_PARCEL_EVENT_SUCCESS,
  FETCH_SUPPORT_DATA,
  FETCH_SUPPORT_DATA_ERROR,
  FETCH_SUPPORT_DATA_SUCCESS,
  RECEIVE_ORDERS,
  RECEIVE_ORDERS_ERROR,
  RECEIVE_ORDERS_SUCCESS,
  UPDATE_SUPPORT_DATA_ERROR
} from "../types";

export const fetchSupportDetails = (compareData) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_SUPPORT_DATA,
    });
    const { data } = await AxiosInstance.get(`admin/orders`, {
      params: compareData,
    });
    dispatch({
      type: FETCH_SUPPORT_DATA_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_SUPPORT_DATA_ERROR,
      payload: "Something went wrong!",
    });
  }
};

export const receiveOrders = (compareData) => async (dispatch) => {
    try {
      dispatch({
        type: RECEIVE_ORDERS
      });
      const {data} = await AxiosInstance.post(`admin/orders/receive/all`, {
        orders: compareData,
      });
      dispatch({
        type: RECEIVE_ORDERS_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: RECEIVE_ORDERS_ERROR,
        payload: "Something went wrong!"
      });
    }
};

export const changeParcelEvent = (compareData) => async(dispatch) => {
  try {
    dispatch({
      type: CHANGE_PARCEL_EVENT
    });
    const {data} = await AxiosInstance.post(`admin/orders/event/many`, compareData);
    dispatch({
      type: CHANGE_PARCEL_EVENT_SUCCESS,
      payload: data.data
    });
  }  catch (error) {
    dispatch({
      type: CHANGE_PARCEL_EVENT_ERROR,
      payload: "Something went wrong"
    })
  }
}

export const addCourierToOrders = (compareData) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_COURIER_TO_ORDERS
    });

    const {data} = await AxiosInstance.post(`admin/orders/add/courier`, compareData);

    dispatch({
      type: ADD_COURIER_TO_ORDERS_SUCCESS,
    });
  }

  catch (error) {
    dispatch({
      type: ADD_COURIER_TO_ORDERS_ERROR,
      payload: "Something went wrong!"
    })
  }
}


export const supportDetailsUpdate = (id,row) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_SUPPORT_DATA,
    });
    const { data } = await AxiosInstance.put(`admin/orders/${id}`,row);
    dispatch({
      type: UPDATE_SUPPORT_DATA_ERROR,
      payload: data.data,
    });
    return true
  } catch (error) {

    dispatch({
      type: FETCH_SUPPORT_DATA_ERROR,
      payload: "Something went wrong!",
    });
    return false
  }
};

