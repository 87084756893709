export const sliderItems = [
  {
    id: "orders",
    to: "/",
    name: "orders",
  },
  {
    id: "parcel",
    to: "/parcel",
    name: "parcel",
  },
  {
    id: "delivery",
    to: "/delivery",
    name: "delivery",
  },
  {
    id: "lockers",
    to: "/lockers",
    name: "lockers",
  },
  // {
  //   id: "branches",
  //   to: "/branches",
  //   name: "branches",
  // },
  //
  // {
  //   id: "company",
  //   to: "/country",
  //   name: "country",
  // },
  {
    id: "csupport",
    to: "/support",
    name: "support",
  },
  {
    id: "scan",
    to: "/scan-packages",
    name: "scan",
  },
  {
    id: "cargo",
    to: "/cargo",
    name: "cargo",
  },

  // {
  //   id: "haypost",
  //   to: "/haypost",
  //   name: "haypost",
  // },
]
