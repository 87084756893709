import {
  CREATE_PARCEL,
  CREATE_PARCEL_ERROR,
  GET_SINGLE_PARCEL,
  SET_PARCEL,
  SET_PARCEL_ERROR,
  UPDATER_PARCEL,
  UPDATE_PARCEL_ERROR,
  PARCEL_EXPORT_ERROR,
  FILTERED_PARCEL_DATA,
} from "../types";

const initialState = {
  parcel: [],
  allParcel: {},
  oneParcel: {},
  successMessage: "",
  errorMessage: {},
  updateSuccess: "",
  updateError: {},
  exportError: "",
};

export const parcelReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PARCEL:
      return {
        ...state,
        parcel: action.payload.data,
        allParcel: action.payload,
        successMessage: action.payload.message,
      };
    case SET_PARCEL_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };

    case UPDATER_PARCEL:
      return {
        ...state,
        parcel: action.payload.data,
        updateSuccess: action.payload.message,
      };

    case CREATE_PARCEL:
      return {
        ...state,
        parcel: action.payload.data,
        successMessage: action.payload.message,
      };

    case CREATE_PARCEL_ERROR:
      return {
        ...state,
        errorMessage: action.payload?.response?.data?.error,
      };

    case UPDATE_PARCEL_ERROR:
      return {
        ...state,
        errorMessage: action.payload?.response?.data?.error,
      };

    case GET_SINGLE_PARCEL:
      return {
        ...state,
        oneParcel: action.payload.data,
      };

    case PARCEL_EXPORT_ERROR:
      return {
        ...state,
        exportError: action.payload,
      };

    case FILTERED_PARCEL_DATA:
      return {
        ...state,
        parcel: action.payload.data,
      };
    default:
      return state;
  }
};
