import { GET_EVENTS } from "../types";

const initialState = {
  event: [],
};

export const EventReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENTS:
      return {
        ...state,
        event: action.payload,
      };

    default:
      return state;
  }
};
