import styled from "styled-components"

const Flex = styled.div`
  padding: ${({ padding }) => padding};
  width: ${({ width }) => width};
  display: ${({ display }) => display};
  justify-content: ${({ j_content }) => j_content};
  align-items: ${({ align }) => align};
`
export default Flex
