import React from "react"

import { message } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { InputGroup, ButtonGroup } from "../../components"
import { auth } from "../../store/actions/index"

import styles from "./login.module.scss"

const Login = () => {
  const [inputValue, setInputValue] = useState({
    email: "",
    password: "",
  })

  const error = useSelector((state) => state.LoginReducer.errorMessage)
  const success = useSelector((state) => state.LoginReducer.successMessage)

  const isLoggedIn = localStorage.getItem("accessToken")

  const navigate = useNavigate()

  const dispatch = useDispatch()

  useEffect(() => {
    if (isLoggedIn) {
      setTimeout(() => {
        navigate("/")
      }, 1000)
    }
  }, [isLoggedIn])

  const onChange = (e) => {
    const { name, value } = e.target
    setInputValue({ ...inputValue, [name]: value })
  }

  const handleLogin = (e) => {
    e.preventDefault()
    dispatch(auth(inputValue, navigate))
  }

  return (
    <>
      {success && message.success(success)}
      {error && message.error(error)}

      <div style={{ display: "flex", justifyContent: "center" }}>
        <form className={styles.login_wrapper}>
          <InputGroup
            className={styles.login_input}
            type="email"
            name="email"
            value={inputValue.email}
            onChange={onChange}
          />
          <br />
          <InputGroup
            className={styles.login_input}
            type="password"
            name="password"
            value={inputValue.password}
            onChange={onChange}
          />

          <br />
          <ButtonGroup className={styles.login_button} onClick={handleLogin}>
            Sign In
          </ButtonGroup>
        </form>
      </div>
    </>
  )
}

export default Login
