import axios from "axios"
// let API_URL = "https://api.onex.am/api";
let API_URL = "https://dev.api.onex.am/api";
// let API_URL = "http://192.168.0.173:8000/api";

export const AxiosInstance = axios.create({
     baseURL: API_URL,

})

AxiosInstance.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("accessToken")
    if (authToken) {
      config.headers.authorization = `Bearer ${authToken}`
    }

    return config
  },
  (error) => Promise.reject(error)
)

AxiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const {
      status,
      config: { url },
      data,
    } = error.response

    if (
      (status === 401 && url !== "login") ||
      data?.detail?.includes("Invalid token")
    ) {
      localStorage.removeItem("accessToken")
      window.location.pathname = "/login"
    }

    return Promise.reject(error)
  }
)

export const AxiosInstance2 = axios.create({
   baseURL: API_URL,
  headers: {
    "Content-Type": "application/vnd.ms-excel",
    Accept: "application/vnd.ms-excel",
  },
})

AxiosInstance2.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("accessToken")
    if (authToken) {
      config.headers.authorization = `Bearer ${authToken}`
    }

    return config
  },
  (error) => Promise.reject(error)
)

